import React, { useCallback } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Form, FormGroup, FormFeedback, Label, Input, Button } from 'reactstrap'
import {schemaProps, FormHandler} from "../Utils/FormHandler";

import { Loading } from '../Utils/Loading'

import { authActions } from '../../redux/actions'

export const LoginPage = (props) => {

    const { isLoading, error, isAuthenticated } = useSelector(store => store.auth)
    const dispatch = useDispatch();

    const formSchema = {
        username: {
            value: '',
            [schemaProps.VALIDATOR]: {
                required: true,
            }
        },
        password: {
            value: '',
            [schemaProps.VALIDATOR]: {
                required: true
            }
        }
    }

   function handleLogin({ username, password }) {
       dispatch(authActions.login(username, password))
   }
   const {
       fieldsValue, fieldsError, isDisabled, fieldsValid, fieldsInvalid,
       handleOnChange, handleOnSubmit, handleServiceErrors
    } = FormHandler(formSchema, handleLogin)

   const { username, password } = fieldsValue;

   if(error) {
       handleServiceErrors(error.invalid || [])
   }

   const ErrorDiv = useCallback(() => {
       if(error) {
           return <div className="alert alert-danger" role="alert">{error.message || 'Unknown error has occured'}</div>
       } else {
           return <div />;
       }
   },[error])


   if(isAuthenticated) {
        return <Route><Redirect to='/' /></Route>
    } else if(isLoading) {
        return <Loading />
    }   

    return (
        <Card body>
            <CardBody>
                <ErrorDiv />
                <Form onSubmit={handleOnSubmit}>
                    <FormGroup>
                        <Label htmlFor="username">Username</Label>
                        <Input type="text"
                            valid={fieldsValid.username}
                            invalid={fieldsInvalid.username}
                            placeholder="Username"
                            name="username"
                            onChange={handleOnChange}
                            value={username}
                            required />
                            <FormFeedback>{fieldsError.username}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Input type="password"
                            valid={fieldsValid.password}
                            invalid={fieldsInvalid.password}
                            placeholder="Password"
                            name="password"
                            onChange={handleOnChange}
                            value={password}
                            required />
                            <FormFeedback>{fieldsError.password}</FormFeedback>
                    </FormGroup>
                    <Button type="submit" value="submit" color="primary" disabled={isDisabled}>Login</Button>
                </Form>  
            </CardBody>
        </Card>
    )
    
}