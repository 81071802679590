import React from 'react';

export const PrivacyPage = (_) => {

    return (
        <>
            <h1>Privacy Policy</h1>
            <p>Effective date: December 3, 2022</p>
            <p>
                Job Passion Assessment ("us", "we", or "our") operates the https://www.jobpassionassessment.com
                website (the "Site").
            </p>

            <p>
                This page informs you of our policies regarding the collection, use, and disclosure of
                personal data when you use our Service and the choices you have associated with that
                data.
            </p>
            <p>
                We use your data to provide and improve the Service. By using the Service, you agree to
                the collection and use of information in accordance with this policy. Unless otherwise
                defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions, accessible from https://www.jobpassionassessment.com/terms-
                and-conditions.
            </p>

            <h2>Definitions</h2>
            <ul>
                <li><strong>Site</strong> means the website https://www.jobpassionassessment.com website.</li>
                <li>
                    Products or Services means the products or services promoted, sold, available for
                    sale, or available for use on the Site, such as our Assessments Reports, and
                    Supplemental Support Programs.
                </li>
                <li>
                    <strong>Personal Data</strong> means data about a living individual who can be identified from those
                    data (or from those and other information either in our possession or likely to come
                    into our possession).
                </li>
                <li>
                    <strong>Usage Data</strong> is data collected automatically either generated by the use of the
                    Service or from the Service infrastructure itself (for example, the duration of a page
                    visit).
                </li>
                <li><strong>Assessment Data</strong> is data collected as responses to online questionnaires.</li>
                <li><strong>Cookies</strong> are small pieces of data stored on your device (computer or mobile device).</li>
                <li>
                    <strong>Data Controller</strong> means the natural or legal person who (either alone or jointly or in
                    common with other persons) determines the purposes for which and the manner in
                    which any personal information is, or is to be, processed. For the purpose of this
                    Privacy Policy, we are a Data Controller of your Personal Data.
                </li>
                <li>
                    <strong>Data Processors</strong> (or Service Providers) means any natural or legal person who
                    processes the data on behalf of the Data Controller. We may use the services of
                    various Service Providers in order to process your data more effectively.
                </li>
                <li>
                    <strong>Data Subject (or User)</strong> is any living individual who is using our Service and is the
                    subject of Personal Data.
                </li>
                <li>
                    <strong>Assessment</strong> means an online questionnaire completed on our Service by a
                    Respondent with the aim of us generating one or more Reports.
                </li>
                <li><strong>Respondent</strong> means an individual who takes an Assessment using our Service.</li>
                <li>
                    <strong>Reports</strong> are the analysis of the responses and recommendations provided in
                    connection with an Assessment.
                </li>
            </ul>

            <h2>Information Collection and Use</h2>
            <p>
                We collect several different types of information for various purposes to provide and
                improve our Service to you.
            </p>

            <h2>Types of Data Collected</h2>
            <h3>Personal Data</h3>
            <p>
                While using our Service, we may ask you to provide us with certain personally identifiable
                information that can be used to contact or identify you ("Personal Data"). Personally
                identifiable information may include, but is not limited to:
            </p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>ookies and Usage Data</li>
            </ul>
            <p>
                We may use your Personal Data to generate your Reports, contact you with newsletters,
                marketing or promotional materials, and other information that may be of interest to you.
                You may opt out of receiving any, or all, of these communications from us by following the
                unsubscribe link or instructions provided in any email we send.
            </p>

            <h3>Assessment Data</h3>
            <p>
                If you choose to become a Respondent, we collect your responses to online Assessments
                to perform the analysis required to generate your Reports. Your Assessment Data must be
                associated with your Personal Data for this purpose.
            </p>

            <h3>Usage Data</h3>
            <p>
                We may also collect information how the Service is accessed and used ("Usage Data").
                This Usage Data may include information such as your computer's Internet Protocol
                address (e.g. IP address), browser type, browser version, the pages of our Service that you
                visit, the time and date of your visit, the time spent on those pages, unique device identifiers
                and other diagnostic data.
            </p>

            <h3>Tracking &amp; Cookies Data</h3>
            <p>
                We use cookies and similar tracking technologies to track the activity on our Service and
                hold certain information.
            </p>
            <p>
                Cookies are files with small amounts of data which may include an anonymous unique
                identifier. Cookies are sent to your browser from a website and stored on your device.
            </p>
            <p>
                Tracking technologies also used are beacons, tags, and scripts to collect and track
                information and to improve and analyze our Service.
            </p>
            <p>
                You can instruct your browser to refuse all cookies or to indicate when a cookie is being
                sent. However, if you do not accept cookies, you may not be able to use some portions of
                our Service.
            </p>
            <p>
                Examples of Cookies we use:
            </p>
            <ul>
                <li>Session Cookies. We use Session Cookies to operate our Service.</li>
                <li>Preference Cookies. We use Preference Cookies to remember your preferences
                and various settings.</li>
                <li>Security Cookies. We use Security Cookies for security purposes.</li>
            </ul>

            <h2>Use of Data</h2>
            <p>
                Job Passion Assessment uses the collected data for various purposes:
            </p>
            <ul>
                <li>To provide and maintain our Service</li>
                <li>To notify you about changes to our Service</li>
                <li>To allow you to participate in interactive features of our Service when you choose to
                do so</li>
                <li>To provide customer support</li>
                <li>To gather analysis or valuable information so that we can improve our Service</li>
                <li>To monitor the usage of our Service</li>
                <li>To detect, prevent and address technical issues</li>
                <li>To provide you with news, special offers and general information about other goods,
                services and events which we offer that are similar to those that you have already
                purchased or enquired about unless you have opted not to receive such information.</li>
            </ul>

            <h2>Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)</h2>
            <p>
                If you are from the European Economic Area (EEA), the Job Passion Assessment legal basis for
                collecting and using the personal information described in this Privacy Policy depends on
                the Personal Data we collect and the specific context in which we collect it.
            </p>
            <p>
                Job Passion Assessment may process your Personal Data because:
            </p>
            <ul>
                <li>We need to perform a contract with you</li>
                <li>You have given us permission to do so</li>
                <li>The processing is in our legitimate interests and it's not overridden by your rights</li>
                <li>For payment processing purposes</li>
                <li>To comply with the law</li>
            </ul>

            <h2>Retention of Data</h2>
            <p>
                Job Passion Assessment will retain your Personal Data only for as long as is necessary for the
                purposes set out in this Privacy Policy. We will retain and use your Personal Data to the
                extent necessary to comply with our legal obligations (for example, if we are required to
                retain your data to comply with applicable laws), resolve disputes, and enforce our legal
                agreements and policies.
            </p>
            <p>
                Job Passion Assessment will also retain Usage Data for internal analysis purposes. Usage Data
                is generally retained for a shorter period of time, except when this data is used to
                strengthen the security or to improve the functionality of our Service, or we are legally
                obligated to retain this data for longer time periods.
            </p>

            <h2>Transfer of Data</h2>
            <p>
                Your information, including Personal Data, may be transferred to — and maintained on —
                computers located outside of your state, province, country or other governmental jurisdiction
                where the data protection laws may differ than those from your jurisdiction.
            </p>
            <p>
                If you are located outside United States and choose to provide information to us, please
                note that we transfer the data, including Personal Data, to the United States and process it
                there.
            </p>
            <p>
                Your consent to this Privacy Policy followed by your submission of such information
                represents your agreement to that transfer.
            </p>
            <p>
                Job Passion Assessment will take all steps reasonably necessary to ensure that your data is
                treated securely and in accordance with this Privacy Policy and no transfer of your Personal
                Data will take place to an organization or a country unless there are adequate controls in
                place including the security of your data and other personal information.
            </p>

            <h2>Disclosure of Data</h2>
            <h3>Disclosure for Law Enforcement</h3>
            <p>
                Under certain circumstances, Job Passion Assessment may be required to disclose your
                Personal Data if required to do so by law or in response to valid requests by public
                authorities (e.g. a court or a government agency).
            </p>

            <h3>Legal Requirements</h3>
            <p>
                Job Passion Assessment may disclose your Personal Data in the good faith belief that such
                action is necessary to:
            </p>
            <ul>
                <li>To comply with a legal obligation</li>
                <li>To protect and defend the rights or property of Job Passion Assessment</li>
                <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>To protect the personal safety of users of the Service or the public</li>
                <li>To protect against legal liability</li>
            </ul>

            <h2>Security of Data</h2>
            <p>
                The security of your data is important to us, but remember that no method of transmission
                over the Internet, or method of electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we cannot guarantee its
                absolute security.
            </p>

            <h2>"Do Not Track" Signals Under California Online Privacy Protection Act (CalOPPA)</h2>
            <p>
                We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your
                web browser to inform websites that you do not want to be tracked.
                You can enable or disable Do Not Track by visiting the Preferences or Settings page of your
                web browser.
            </p>

            <h2>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h2>
            <p>
                If you are a resident of the European Economic Area (EEA), you have certain data
                protection rights. Job Passion Assessment aims to take reasonable steps to allow you to
                correct, amend, delete, or limit the use of your Personal Data.
            </p>
            <p>
                If you wish to be informed what Personal Data we hold about you and if you want it to be
                removed from our systems, please contact us.
            </p>
            <p>
                In certain circumstances, you have the following data protection rights:
            </p>
            <ul>
                <li>The right to access, update or to delete the information we have about
                you. Whenever made possible, you can access, update or request deletion of your
                Personal Data directly within your account settings section. If you are unable to
                perform these actions yourself, please contact us to assist you.</li>
                <li>The right of rectification. You have the right to have your information rectified if
                that information is inaccurate or incomplete.</li>
                <li>The right to object. You have the right to object to our processing of your Personal
                Data.</li>
                <li>The right of restriction. You have the right to request that we restrict the
                processing of your personal information.</li>
                <li>The right to data portability. You have the right to be provided with a copy of the
                information we have about you in a structured, machine-readable and commonly
                used format.</li>
                <li>The right to withdraw consent. You also have the right to withdraw your consent at
                any time where Job Passion Assessment relied on your consent to process your
                personal information.</li>
            </ul>
            <p>
                Please note that we may ask you to verify your identity before responding to such requests.
                You have the right to complain to a Data Protection Authority about our collection and use
                of your Personal Data. For more information, please contact your local data protection
                authority in the European Economic Area (EEA).
            </p>

            <h2>Service Providers</h2>
            <p>
                We may employ third party companies and individuals to facilitate our Service ("Service
                Providers"), to provide the Service on our behalf, to perform Service-related services or to
                assist us in analyzing how our Service is used.
            </p>
            <p>
                These third parties have access to your Personal Data only to perform these tasks on our
                behalf and are obligated not to disclose or use it for any other purpose.
            </p>

            <h3>Analytics</h3>
            <p>
                We use a third-party Service Provider, Google Analytics, to monitor and analyze the use of
                our Service. Google Analytics is a web analytics service offered by Google that tracks and
                reports website traffic. Google uses the data collected to track and monitor the use of our
                Service. This data is shared with other Google services. Google may use the collected data
                to contextualize and personalize the ads of its own advertising network. You can opt-out of
                having made your activity on the Service available to Google Analytics by installing the
                Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics
                JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics
                about visits activity. For more information on the privacy practices of Google, please visit
                the Google Privacy &amp; Terms web page:
                <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
            </p>

            <h3>Behavioral Remarketing</h3>
            <p>
                Job Passion Assessment may use a third-party Service Provider, Facebook remarketing
                service, to advertise on third party websites to you after you visited our Service. We and our
                third-party vendors use cookies to inform, optimize and serve ads based on your past visits
                to our Service.
            </p>
            <p>
                Facebook remarketing service is provided by Facebook Inc. You can learn more about
                interest-based advertising from Facebook by visiting this
                page: <a href="https://www.facebook.com/help/164968693837950">https://www.facebook.com/help/164968693837950</a>.
            </p>
            <p>
                To opt-out from Facebook's interest-based ads follow these instructions from
                Facebook: <a href="https://www.facebook.com/help/568137493302217">https://www.facebook.com/help/568137493302217</a>.
            </p>
            <p>
                Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising
                established by the Digital Advertising Alliance. You can also opt-out from Facebook and
                other participating companies through the Digital Advertising Alliance in the
                USA <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>,
                the Digital Advertising Alliance of Canada in Canada 
                <a href="http://youradchoices.ca/">http://youradchoices.ca/</a>, or the European Interactive
                Digital Advertising Alliance in Europe
                <a href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/</a>,
                or opt-out using your mobile device settings. For more information on the privacy practices of
                Facebook, please visit Facebook's Data Policy:
                <a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a>
            </p>

            <h3>Email and Marketing Automation</h3>
            <p>
                Job Passion Assessment uses two third-party Service Providers, Kartra, and Bucket.io, to
                collect contact information from forms on our Service and use that contact information for
                email delivery and marketing automation. Kartra is obligated to keep
                your contact information private and to not disclose or use your contact information for any
                purpose other than providing tasks on our behalf.
            </p>

            <h3>Payments</h3>
            <p>
                We may provide paid products and/or services within the Service. In that case, we use third-
                party services for payment processing (e.g. payment processors).
            </p>
            <p>
                We will not store or collect your payment card details. That information is provided directly
                to our third-party payment processors whose use of your personal information is governed
                by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS
                as managed by the PCI Security Standards Council, which is a joint effort of brands like
                Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the
                secure handling of payment information. The payment processors we work with are:
            </p>
            <ul>
                <li>
                    Stripe. Their Privacy Policy can be viewed at
                    <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>
                </li>
                <li>
                    PayPal. Their Privacy Policy can be viewed at
                    <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">https://www.paypal.com/webapps/mpp/ua/privacy-full</a>
                </li>
            </ul>

            <h2>Links to Other Sites</h2>
            <p>
                Our Service may contain links to other sites that are not operated by us. If you click on a
                third-party link, you will be directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
            </p>
            <p>
                We have no control over and assume no responsibility for the content, privacy policies or
                practices of any third-party sites or services.
            </p>

            <h2>Children's Privacy</h2>
            <p>
                Our Service does not address anyone under the age of 18 ("Children").
            </p>
            <p>
                We do not knowingly collect personally identifiable information from anyone under the age
                of 18. If you are a parent or guardian and you are aware that your child has provided us with
                Personal Data, please contact us. If we become aware that we have collected Personal
                Data from children without verification of parental consent, we take steps to remove that
                information from our servers.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by
                posting the new Privacy Policy on this page.
            </p>
            <p>
                We will let you know via email and/or a prominent notice on our Service, prior to the change
                becoming effective and update the "effective date" at the top of this Privacy Policy.
            </p>
            <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this
                Privacy Policy are effective when they are posted on this page.
            </p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us.</p>
            <p>
                By Mail:<br />
                Job Passion Assessment<br />
                A Division of KASCORP Holdings Ltd.<br />
                15 Allstate Parkway, Suite 600<br />
                Markham, ON L3R 5B4
            </p>
            <p>
                By Email:<br />
                <a href="mailto:support@jobpassionassessment.com" target="_blank">support@jobpassionassessment.com</a>
            </p>
        </>
    )
}