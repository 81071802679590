import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import ReactGA from 'react-ga'

import aipng from '../../../assets/ActuaryImage.png'
import wbt from '../../../assets/DreamJobBookCover400.png'


export const TitlesPage = ({ThemeItems, themeListCount}) => {

    if(themeListCount == 0) {
        return <div>
                    Because we were not able to define a DreamJob Type, we are not able to define any DreamJob Titles.
                    Please contact <a href="mailto:support@dreamjobdiscovery.com" target="_blank">support@dreamjobdiscovery.com</a>
                    {' '} for assistance.
                </div>
        
    }

    return (
        <Container>
            <h1>Your DreamJob Titles&trade;</h1>
            <p>
                Employers typically post their job opportunities following the Standard Occupational
                Classification (SOC) code list. There are more than 800 occupation titles on this
                list, and we've linked each one to different DreamJob Types based on how well
                they match the Core and Supporting values that you'll need for success in that type.
            </p>
            <p>
                Because we've linked every relevant occupation title to each DreamJob Type, the
                result is a comprehensive list of job possibilities that you may never have
                considered before.
            </p>
            <p>
                The benefit of providing such a wide variety of options is to open up your mind to
                consider new career opportunities that could allow you to think broader or become
                unstuck from your current situation.
            </p>
            <p>
                However, because they cover the entire spectrum of possible job roles and industry
                sectors, you should expect that only a few of the suggestions on the list will be
                of interest to you.
            </p>
            <p>
                Here are the job title suggestions for each of your top-three DreamJob Types:
            </p>

            <ThemeItems />

            <h2>What To Do Next</h2>
            <p>
                Start with the DreamJob Type that you feel is the best fit for you.
            </p>

            <div className="my-3">
                <Row className="align-items-center">
                    <Col xs="12" sm="4" md="3"><img className="d-block mw-100" src={aipng} alt={`Dream Job Discovery`} /></Col>
                    <Col className="mt-4">
                        <p>Carefully review the job title suggestions for that DreamJob Type.</p>
                        <p>
                            Click on any job title to pop up a description of that job role as shown
                            in this example for Actuary.
                        </p>
                        <p>
                            Create a shortlist of job title suggestions for you to investigate further
                            by entering a check mark beside the ones that resonate most with you.
                        </p>
                    </Col>
                </Row>
            </div>



            <div className="alert-warning p-4 my-3">
                <Row className="align-items-center">
                    <Col xs="12" sm="4" md="2">
                        <ReactGA.OutboundLink
                            eventLabel="download_workbook_image"
                            to="https://www.amazon.com/Dream-Job-Discovery-Passion-Inspires-ebook/dp/B09FB1HT6X/ref=sr_1_1?dchild=1&amp;keywords=Dream+Job+Discovery&amp;qid=1631055764&amp;s=books&amp;sr=1-1"
                            target="_blank"
                        ><img className="d-block mw-100" src={wbt} alt={`Dream Job Discovery`} /></ReactGA.OutboundLink>
                    </Col>
                    <Col>
                        <p className="mt-4">
                            Be sure to read Chapter 7 - How Do You Transition? - in Dream Job Discovery for a
                            detailed explanation of how to filter down your job title suggestions using your
                            Sanctioning values, Character values, and the Traits you discover about yourself
                            in Chapter 6.
                        </p>
                    </Col>
                </Row>
            </div>
            

            <h2>We're Here To Help</h2>
            <p>
                Whatever you need on your DreamJob Discovery journey, we're here to make sure you're successful.
                That's because we want you to be happy with your life. And, we know that lasting happiness is
                linked to finding work that fuels your passions and gives you a sense of purpose.
            </p>
            <p>
                To discover how we can help you leverage the results of your DJTI assessment to finally land
                a job that will be a dream for you to do, click the button below.
            </p>
            <p>
                <ReactGA.OutboundLink
                    eventLabel="discover_more"
                    to="https://www.dreamjobdiscovery.com/services"
                    target="_blank"
                >
                    <button className="btn" style={
                        {
                            paddingTop: "10px", paddingBottom: "10px", paddingLeft: "15px", paddingRight: "15px",
                            fontWeight: "bold", color: "#2c3b59", backgroundColor: "#62CE62"}
                        }>DISCOVER MORE</button>
                </ReactGA.OutboundLink>
            </p>


        </Container>
    )
}
