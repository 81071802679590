import { userConstants as constants } from '../constants';
import { reducerHelpers } from '../helpers';

export function users(state = {
    isLoading: false,
    isSuccess: false,
    error: null,
    lastRequest: null
}, action) {
  switch (action.type) {

    case constants.REGISTER_REQUEST:
        return {...state,
            isLoading: true,
            isSuccess: false,
            lastRequest: reducerHelpers.initRequestTime()
        };

    case constants.REGISTER_SUCCESS:

        reducerHelpers.processSuccessEvent('Register', state.lastRequest)

        return {...state,
            isLoading: false,
            isSuccess: true,
            error: null
        };

    case constants.REGISTER_FAILURE:

        reducerHelpers.processFailureEvent('Register', state.lastRequest, action.error)

        return {...state,
            isLoading: false,
            isSuccess: false,
            error: action.error
        };

    case constants.PASSWORD_REQUEST:
        return {...state,
            isLoading: true,
            isSuccess: false,
            lastRequest: reducerHelpers.initRequestTime()
        };

    case constants.PASSWORD_SUCCESS:

        reducerHelpers.processSuccessEvent('Password Reset', state.lastRequest)

        return {...state,
            isLoading: false,
            isSuccess: true,
            error: null
        };

    case constants.PASSWORD_FAILURE:

        reducerHelpers.processFailureEvent('Password Reset', state.lastRequest, action.error)

        return {...state,
            isLoading: false,
            isSuccess: false,
            error: action.error
        };

    default:
        return state
  }
}