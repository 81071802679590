import { serviceHelpers } from '../helpers';

export const assessmentServices = {
    save,
    getPage
};

function save(questions, newPage) {

    const requestOptions = {
        method: 'PUT',
        headers: serviceHelpers.getServiceHeaders(true),
        body: JSON.stringify(questions)
    };

    const url = serviceHelpers.getServiceUrl('/question');    

    return fetch(url, requestOptions)
    .then(serviceHelpers.handleServiceResponse)
    .then((t) => {
        return getPage(newPage)
    })
    .catch(serviceHelpers.handleFetchError)
}

function getPage(page) {

    const requestOptions = {
        method: 'GET',
        headers: serviceHelpers.getServiceHeaders(true)
    };

    let options = {}
    if(page) options.page = page;

    const url = serviceHelpers.getServiceUrl('/question', options);

    return fetch(url, requestOptions)
    .then(serviceHelpers.handleServiceResponse)
    .catch(serviceHelpers.handleFetchError)
}