import React from 'react'

import { Container } from 'reactstrap'

const SanctioningValuesDesc = {
    'Activity': 'You will likely be miserable doing a job that requires you to sit most of the day. Instead, look for jobs that require a lot of walking, movement, or work outside.',
    'Affluence': 'Make sure that whatever passions you are pursuing have the potential to provide enough income to not only cover your bills but also create the lifestyle you desire.',
    'Balance': 'If maintaining a balance between your work life and your recreational life is a Sanctioning value for you, avoid jobs where working overtime and weekends is expected.',
    'Cleanliness': 'If keeping yourself and your workspace clean while you work is important, you\'ll generally prefer to work in office environments and avoid jobs that involve fixing or fabricating.',
    'Devoutness': 'Ensure that any job you seek with any employer will not interfere with your religious beliefs or practices.',
    'Diversity': 'You will prefer to work at companies that are inclusive in hiring people of all different backgrounds and beliefs.',
    'Family': 'If being present to love, support, and care for your family is a high priority in your life, then look for jobs that permit you to be home for dinner most nights.',
    'Outdoors': 'You will not be happy working indoors all day if you have a Sanctioning value of outdoors. Instead, seek a job role where you can enjoy the fresh air and sunshine.',
    'Solitude': 'If you value thinking and working alone, you\'ll want to avoid any job role that requires teamwork for success, or jobs where you must sit in meetings and participate in group discussion most of the time.',
    'Sustainability': 'You will feel most comfortable in roles at organizations that do not deplete natural resources or harm the environment.',
    'Teamwork': 'Avoid jobs that require working alone most of your time with infrequent interaction with others.',
    'Variety': 'Look for jobs where you have exposure to new projects, new problems to solve, and new things to learn, and avoid those that require daily repetition of the same tasks.'
}

const characterValuesDesc = {
    'Accountability': 'You would be well suited to job roles that not only require tasks to be completed, but where you have direct responsibility for delivering specific results on time, and on budget.',
    'Adaptability': 'Look for job roles where the ability to go with the flow and quickly pivot in reaction to changing conditions is critical to success.',
    'Benevolence': 'If benevolence is one of your Character values, you will feel at ease doing any job where authentic kindness and generosity are required. You may also feel a strong sense of meaning and purpose in doing that work.',
    'Change': 'Because you love to challenge the status quo, any job where you get to challenge the way things are done would likely be a good fit.',
    'Composure': 'You would be well suited to job roles where remaining calm under pressure is an asset.',
    'Discipline': 'Look for job roles that require you to follow established routines, procedures, protocols, or curriculums.',
    'Endurance': 'This Character value will serve you well in jobs that require you to endure unpleasant or difficult situations for extended periods without giving way.',
    'Exhilaration': 'You might be a good fit for a job that creates an adrenaline rush.',
    'Integrity': 'Avoid any jobs that use deceptive or unethical business practices.',
    'Proactivity': 'You will likely feel more comfortable in jobs that require you to anticipate and implement actions to avoid problems before they occur, as opposed to reacting to issues as they pop up.',
    'Punctuality': 'You might want to avoid any job opportunities that require punctuality if that is not one of your Character values.',
    'Self-motivation': 'This Character value will serve you well in jobs where you tend to work in isolation without the benefit of inspiration that is typically provided by teamwork.',
    'Spirituality': 'If you are more concerned with the human spirit and soul than material things, you will find happiness in job roles that deal primarily with human self-improvement or self-development.'
}

export const Appendix = () => {
    return (
        <Container>
            <h1>Appendix</h1>

            <h2>Understanding How to Use Your Sanctioning Values</h2>
            <p>
                Each of your Sanctioning values are used as a filter to screen out specific
                job opportunities that ultimately won't be a good fit for you, even though
                they align nicely with your Job Passion Types. So, think of them as your "go"
                versus "no go" values.
            </p>
            <p>
                Based on the results of your JPTI assessment, look for your Sanctioning values in
                the list below and consider what clues they give you about the job opportunities
                that would be a good fit for you to pursue either in a new job or a promotion from
                your current role:
            </p>

            {Object.keys(SanctioningValuesDesc).map((i) => {
                return <p key={'sancvaldesc' + i}><strong>{i}</strong> - {SanctioningValuesDesc[i]}</p>
            })}

            <h2>Understanding How To Use Your Character Values</h2>
            <p>
                Your Character values describe the kind of person you tend to be in terms of your
                character. They are clues to how you might prefer to do things while at work, so
                you can think of them as your "work style" values.
            </p>
            <p>
                Look for your Character values in the following list to determine how to use them
                to decide which job opportunities are the best choice for you to pursue:
            </p>

            {Object.keys(characterValuesDesc).map((i) => {
                return <p key={'charcvaldec' + i}><strong>{i}</strong> - {characterValuesDesc[i]}</p>
            })}
        </Container>
    )
}