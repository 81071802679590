import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'

export const PrivateRoute = ({ component: Component, ...rest }) => {

    const insecureRedirect = rest.useRedirect || '/login';
    const { isAuthenticated } = useSelector(store => store.auth)

    console.log(rest);
    return (<Route {...rest} render={props => (
        isAuthenticated
            ? <Component {...props} />
            : <Redirect to={{ pathname: insecureRedirect, state: { from: props.location } }} />
    )} />);
}