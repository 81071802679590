import { authConstants as constants } from '../constants';
import moment from 'moment';
import { reducerHelpers } from '../helpers';
import ReactGA from 'react-ga'
import { setDomainBrand, resetDomainBrand } from '../../components/Branding';

let rawSession = localStorage.getItem('session') || null
let initialSession = null
let initialError = null

if(rawSession) {
    initialSession = JSON.parse(rawSession)
    if(!initialSession.expires || moment().diff(initialSession.expires) > 0) {
        //Session expired
        localStorage.removeItem('session')
        initialSession = null;
        initialError = {code: 'invalid', message: 'Your session expired', errors: []}
    }
}

if(initialSession?.domain || false) {
    setDomainBrand(initialSession?.domain)
}

export const auth = (
    state = {
        isLoading: false,
        isAuthenticated: initialSession ? true : false,
        data: initialSession,
        error: initialError,
        invalid: null,
        lastRequest: null
    },
    action) => {
    switch (action.type) {
        case constants.LOGIN_REQUEST:
            return {...state,
                isLoading: true,
                isAuthenticated: false,
                data: null,
                lastRequest: reducerHelpers.initRequestTime()
            };

        case constants.LOGIN_SUCCESS:

            if(!action.data?.domain || false) {
                action.data.domain = ''
            }

            reducerHelpers.processSuccessEvent('Login', state.lastRequest)

            localStorage.setItem('session', JSON.stringify(action.data))

            setDomainBrand(action.data.domain)            

            ReactGA.set({userId: action.data.user_id})

            return {...state,
                isLoading: false,
                isAuthenticated: true,
                data: action.data,
                error: null,
                invalid: null
            };

        case constants.LOGIN_FAILURE:

            reducerHelpers.processFailureEvent('Login', state.lastRequest, action.error)

            return {...state,
                isLoading: false,
                isAuthenticated: false,
                data: null,
                error: action.error,
                validationErrors: action.error.invalid
            };

        case constants.LOGOUT_REQUEST:
            return {...state,
                isLoading: true,
                lastRequest: reducerHelpers.initRequestTime()
            };

        case constants.LOGOUT_SUCCESS:
            resetDomainBrand()

            reducerHelpers.processSuccessEvent('Logout', state.lastRequest)

            localStorage.removeItem('session')
            
            return {...state,
                isLoading: false,
                isAuthenticated: false,
                data: null,
                error: null,
                invalid: null
            };

        case constants.LOGOUT_FAILURE:

            reducerHelpers.processFailureEvent('Logout', state.lastRequest, action.error)
            
            return {...state,
                isLoading: false,
                isAuthenticated: false,
                data: null,
                error: action.error,
                validationErrors: action.error.invalid
            };

        default:
            return state
    }
}