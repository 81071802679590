import { serviceHelpers } from '../helpers';

export const userServices = {
    passwordReset,
    register
};

function register(email) {

    const requestOptions = {
        method: 'POST',
        headers: serviceHelpers.getServiceHeaders(false),
        body: JSON.stringify({ email })
    };

    const url = serviceHelpers.getServiceUrl('/auth/local');    

    return fetch(url, requestOptions)
    .then(serviceHelpers.handleServiceResponse)
    .catch(serviceHelpers.handleFetchError)
}

function passwordReset(email) {

    const requestOptions = {
        method: 'PATCH',
        headers: serviceHelpers.getServiceHeaders(false),
        body: JSON.stringify({ email })
    };

    const url = serviceHelpers.getServiceUrl('/auth/local');

    return fetch(url, requestOptions)
    .then(serviceHelpers.handleServiceResponse)
    .catch(serviceHelpers.handleFetchError)
}