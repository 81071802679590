import React, { useState } from "react";
import { Switch, Route } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import { FinalPage } from '../Assessment'
import { ResultThemes, ResultValues, ResultNextSteps, ResultPage, ResultTitles, Appendix } from '../Result'
import { DemographicPage } from '../Demographic'

import { ResultReportTest } from './Report'

import { GetBrandedPage, getBrandName } from '../Branding'

const HomePage = GetBrandedPage({pageName: 'home'})

const themeDesc1 = "<p>_BRAND_NAME_ Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam feugiat nibh eget leo luctus, id sagittis diam elementum. Fusce elit urna, vestibulum a purus sed, efficitur interdum enim. Nam tristique nunc diam. Integer rutrum lorem sed nunc hendrerit efficitur. Suspendisse ullamcorper dapibus eros et maximus. Suspendisse viverra tortor a elit euismod hendrerit. Vivamus nisl sem, aliquam quis rutrum elementum, porta ac enim.</p><p>Nulla ullamcorper risus dui, nec tempus urna efficitur quis. Quisque a diam quis risus sodales pellentesque et id libero. Aenean convallis fringilla volutpat. Aliquam euismod dictum ultricies. Aenean id rutrum mi, eu tincidunt sapien. Suspendisse maximus dui sapien, in rutrum arcu sagittis ac. Pellentesque auctor mollis augue, et ullamcorper enim suscipit ac. Aliquam pretium feugiat tellus nec mattis. Vivamus vel scelerisque mi, id rutrum nulla.</p>"
const themeDesc2 = "_BRAND_NAME_ Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam feugiat nibh eget leo luctus, id sagittis diam elementum. Fusce elit urna, vestibulum a purus sed, efficitur interdum enim. Nam tristique nunc diam. Integer rutrum lorem sed nunc hendrerit efficitur. Suspendisse ullamcorper dapibus eros et maximus. Suspendisse viverra tortor a elit euismod hendrerit. Vivamus nisl sem, aliquam quis rutrum elementum, porta ac enim.<p>Nulla ullamcorper risus dui, nec tempus urna efficitur quis. Quisque a diam quis risus sodales pellentesque et id libero. Aenean convallis fringilla volutpat. Aliquam euismod dictum ultricies. Aenean id rutrum mi, eu tincidunt sapien. Suspendisse maximus dui sapien, in rutrum arcu sagittis ac. Pellentesque auctor mollis augue, et ullamcorper enim suscipit ac. Aliquam pretium feugiat tellus nec mattis. Vivamus vel scelerisque mi, id rutrum nulla.</p>"
const themeDesc3 = "<p>broken p tag _BRAND_NAME_ _BRAND_NAME_ _BRAND_NAME_"

const mockCoreValues = [
    {id: 'c1', name: 'core 1', desc: 'core desc 1', value: 5, selected: false},
    {id: 'c2', name: 'core 2', desc: 'core desc 2', value: 5, selected: false},
    {id: 'c3', name: 'core 3', desc: 'core desc 3', value: 5, selected: false},
    {id: 'c4', name: 'core 4', desc: 'core desc 4', value: 5, selected: false}
]

const mockSupportValues = [
    {id: 's1', name: 'support 1', desc: 'support desc 1', value: 4, selected: false},
    {id: 's2', name: 'support 2', desc: 'support desc 2', value: 4, selected: false},
    {id: 's3', name: 'support 3', desc: 'support desc 3', value: 4, selected: false},
    {id: 's4', name: 'support 4', desc: 'support desc 4', value: 4, selected: false}
]

const mockDrivers = [
    {id: 'd1', name: 'driver 1', desc: 'driver desc 1', value: 5, selected: false},
    {id: 'd2', name: 'driver 2', desc: 'driver desc 2', value: 4, selected: false},
    {id: 'd3', name: 'driver 3', desc: 'driver desc 3', value: 5, selected: false},
    {id: 'd4', name: 'driver 4', desc: 'driver desc 4', value: 4, selected: false}
]

const mockCharacterValues = [
    {id: 'h1', name: 'character 1', desc: 'character desc 1', value: 5, selected: false},
    {id: 'h2', name: 'character 2', desc: 'character desc 2', value: 4, selected: false},
    {id: 'h3', name: 'character 3', desc: 'character desc 3', value: 5, selected: false},
    {id: 'h4', name: 'character 4', desc: 'character desc 4', value: 4, selected: false}
]

const mockThemeList = [
    {
        id: 't1', name: 'theme 1', desc: themeDesc1, score: 21, selected: false,
        jobs: [
            {id: 'j11', name: 'ajob 11', desc: 'job 11 desc', soc_code: 'soc code 11', group_name: '', selected: false, filtered: false},
            {id: 'j12', name: 'job 12', desc: 'job 12 desc', soc_code: 'soc code 12', group_name: '', selected: false, filtered: true},
            {id: 'j13', name: 'zjob 13', desc: 'job 13 desc', soc_code: 'soc code 13', group_name: '', selected: false, filtered: false},
            {id: 'j14', name: 'job 14', desc: 'job 14 desc', soc_code: 'soc code 14', group_name: '', selected: false, filtered: true},
            {id: 'j15', name: 'fjob 15', desc: 'job 15 desc', soc_code: 'soc code 15', group_name: '', selected: false, filtered: false}
        ],
        core_values: ['core 11','core 12','core 13'],
        support_values: ['support 14','support 15','support 16']
    },
    {
        id: 't2', name: 'theme 2', desc: themeDesc2, score: 20, selected: false,
        jobs: [
            {id: 'j21', name: 'job 21', desc: 'job 21 desc', soc_code: 'soc code 21', group_name: 'group 1', selected: false, filtered: false},
            {id: 'j22', name: 'job 22', desc: 'job 22 desc', soc_code: 'soc code 22', group_name: 'group 2', selected: false, filtered: true},
            {id: 'j23', name: 'job 23', desc: 'job 23 desc', soc_code: 'soc code 23', group_name: 'group 1', selected: false, filtered: false},
            {id: 'j24', name: 'job 24', desc: 'job 24 desc', soc_code: 'soc code 24', group_name: 'group 2', selected: false, filtered: false},
            {id: 'j25', name: 'job 25', desc: 'job 25 desc', soc_code: 'soc code 25', group_name: 'group 3', selected: false, filtered: false}
        ],
        core_values: ['core 21','core 22','core 23'],
        support_values: ['support 24','support 25','support 26']
    },
    {
        id: 't3', name: 'theme 3', desc: themeDesc3, score: 22, selected: false,
        jobs: [
            {id: 'j21', name: 'job 21', desc: 'job 21 desc', soc_code: 'soc code 21', group_name: 'group 1', selected: false, filtered: false},
            {id: 'j22', name: 'job 22', desc: 'job 22 desc', soc_code: 'soc code 22', group_name: 'group 2', selected: false, filtered: false},
            {id: 'j23', name: 'job 23', desc: 'job 23 desc', soc_code: 'soc code 23', group_name: 'group 1', selected: false, filtered: false},
            {id: 'j24', name: 'job 24', desc: 'job 24 desc', soc_code: 'soc code 24', group_name: 'group 2', selected: false, filtered: false},
            {id: 'j25', name: 'job 25', desc: 'job 25 desc', soc_code: 'soc code 25', group_name: 'group 3', selected: false, filtered: false}
        ],
        core_values: [],
        support_values: []
    }
]

const mockThemeListNoJobs = [
    {
        id: 't1', name: 'theme 1', desc: themeDesc1, score: 21, selected: false,
        jobs: [],
        core_values: ['core 11','core 12','core 13'],
        support_values: ['support 14','support 15','support 16']
    },
    {
        id: 't2', name: 'theme 2', desc: themeDesc2, score: 20, selected: false,
        jobs: [],
        core_values: ['core 21','core 22','core 23'],
        support_values: ['support 24','support 25','support 26']
    },
    {
        id: 't3', name: 'theme 3', desc: themeDesc3, score: 22, selected: false,
        jobs: [],
        core_values: [],
        support_values: []
    }
]

const mockThemeList2 = [
    {
        id: 't1', name: 'theme 1', desc: themeDesc1, score: 21, selected: false,
        jobs: [
            {id: 'j11', name: 'job 11', desc: 'job 11 desc', soc_code: 'soc code 11', group_name: '', selected: false, filtered: false},
            {id: 'j12', name: 'job 12', desc: 'job 12 desc', soc_code: 'soc code 12', group_name: '', selected: false, filtered: false},
            {id: 'j13', name: 'job 13', desc: 'job 13 desc', soc_code: 'soc code 13', group_name: '', selected: false, filtered: false},
            {id: 'j14', name: 'job 14', desc: 'job 14 desc', soc_code: 'soc code 14', group_name: '', selected: false, filtered: false},
            {id: 'j15', name: 'job 15', desc: 'job 15 desc', soc_code: 'soc code 15', group_name: '', selected: false, filtered: false}
        ],
        core_values: ['core 11','core 12','core 13'],
        support_values: ['support 14','support 15','support 16']
    }
]

export const DevScreens = () => {
    console.log('-----------------------', getBrandName())
    if(getBrandName() == 'Job Passion') {
        const joblessThemes = mockThemeList.map(i => {
            i.jobs=null
            return i
        })
    }
    const [mockData, setMockData] = useState({
        core: mockCoreValues, support: mockSupportValues, driver: mockDrivers, character: mockCharacterValues,theme: mockThemeList
    })

    console.log(mockData)

    const onCheckCb = (id, type, state) => {

        switch(type) {
            case 'core':
            case 'support':
            case 'driver':
            case 'character':
                setMockData(data => ({ ...data, [type]: updateListSelected(data[type], id, state)}))
                break;

            case 'theme':
                setMockData(data => ({ ...data, [type]: updateListSelected(data[type], id, state)}))
                break;

            case 'job':
                //more work
                setMockData(data => ({ ...data, ['theme']: updateJobListSelected(data['theme'], id, state)}))
                break;

            default:
                console.error(`Error: type (${type} is not valid)`)
        }

        console.log(`Update type ${type} for id ${id} to state ${state}`)
    }

    const updateJobListSelected = (themes, id, value) => {
        console.log(themes)
        return themes.map(i => {
            i.jobs = updateListSelected(i.jobs, id, value)
            return i
        })
    }

    const updateListSelected = (list, id, value) => {
        return list.map(i => {
            if(i.id == id) {
                i.selected = value
            }
            return i
        })
    }

    const demo = {
        name: 'My Full Name',
        date: 'March 28, 2024',
        position: 'My Position',
        years: 'I\'ve just applied'
    }

    return (
        <Switch>
            <Route exact path="/screen_test/first" component={HomePage} />
            <Route exact path="/screen_test/last" component={FinalPage} />
            <Route exact path="/screen_test/results">
                <ResultPage
                    coreValues={mockData.core}
                    supportValues={mockData.support}
                    drivers={mockData.driver}
                    characterValues={mockData.character}
                    themes={mockData.theme}
                    demo
                    onCheckCb={onCheckCb} />
            </Route>
            <Route exact path="/screen_test/results_values">
                <ResultValues
                    coreValues={mockData.core}
                    supportValues={mockData.support}
                    drivers={mockData.driver}
                    characterValues={mockData.character}
                    onCheckCb={onCheckCb}
                />
            </Route>
            <Route exact path="/screen_test/results_values_none">
                <ResultValues
                    coreValues={[]}
                    supportValues={[]}
                    drivers={[]}
                    characterValues={[]}
                    onCheckCb={onCheckCb}
                />
            </Route>
            <Route exact path="/screen_test/results_themes">
                <ResultThemes themeList={mockData.theme} onCheckCb={onCheckCb} />
            </Route>
            <Route exact path="/screen_test/results_titles">
                <ResultTitles themeList={mockData.theme} onCheckCb={onCheckCb} />
            </Route>
            <Route exact path="/screen_test/results_next_steps">
                <ResultNextSteps  />
            </Route>
            <Route exact path="/screen_test/results_report">
                <ResultReportTest
                    coreValues={mockData.core}
                    supportValues={mockData.support}
                    drivers={mockData.driver}
                    characterValues={mockData.character}
                    themes={mockData.theme}
                    onCheckCb={onCheckCb} />
            </Route>
            <Route exact path="/screen_test/demographics">
                <Row>
                    <Col>
                        <DemographicPage submitCb={(data) => console.log(data)} error={null}  />
                    </Col>
                </Row>
            </Route>
            <Route exact path="/screen_test/results_appendix">
                <Appendix  />
            </Route>
        </Switch>
    )
}