import React from 'react'
import DreamJob from './dreamjob'
import JobPassions from './jobPassions'

let currentBrand = DreamJob

const domainMap = {
    '192.168.15.25': JobPassions,
    'web-dreamjob.ldev.shadowtechllc.net': JobPassions,
    'jpti.jobpassionassessment.com': JobPassions,
    'djti.dreamjobdiscovery.com': DreamJob
}

export const setDomainBrand = (domain) => {
    if(Object.keys(domainMap).includes(domain)) {
        currentBrand = domainMap[domain]
    } else {
        currentBrand = DreamJob
    }
}

export const resetDomainBrand = () => {
    if(Object.keys(domainMap).includes(window.location.hostname)) {
        currentBrand = domainMap[window.location.hostname]
    } else {
        currentBrand = DreamJob
    }
}

export const getFavicon = () => currentBrand.favicon

export const getBrandName = () => {
    return currentBrand.brandName
}

export const getBrandNameFull = () => {
    return currentBrand.brandNameFull
}

export const getBrandLogo = () => {
    return currentBrand.Logo
}

export const getPrintBrandLogo = () => {
    return currentBrand.PrintLogo
}

export const getBrandCoverImage= () => {
    return currentBrand.CoverImage
}

export const allowSignup = () => (currentBrand.SignupPage) ? true : false

export const allowDemographics = () => currentBrand.displayDemographics

export const GetBrandedPage = ({pageName, ...props}) => {
    switch(pageName) {
        case 'home':
            return () => <>{currentBrand.HomePage(props)}</>
        case 'signup':
            if(currentBrand.SignupPage)
                return () => <>{currentBrand.HomePage(props)}</>
            else return <><div>No page found</div></>
        case 'privacy':
            return () => <>{currentBrand.PrivacyPage(props)}</>
        case 'terms':
            return () => <>{currentBrand.TermsPage(props)}</>
        case 'values':
            return () => <>{currentBrand.ValuesPage(props)}</>
        case 'themes':
            return () => <>{currentBrand.ThemesPage(props)}</>
        case 'titles':
            return () => <>{currentBrand.TitlesPage(props)}</>
        case 'next steps':
            return () => <>{currentBrand.NextStepsPage(props)}</>
        default:
            return () => <div>Page not found</div>
    }
}

resetDomainBrand()
