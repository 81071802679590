import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { resultActions } from '../../redux/actions'

import ReactGA from 'react-ga'

import { Container, Row, Col, Button, Modal } from 'reactstrap'

import { ResultValues, ResultThemes, ResultTitles, Appendix } from './'

import { getBrandNameFull, getPrintBrandLogo, getBrandCoverImage } from '../Branding'
import { Loading } from '../Utils/Loading'

export const ResultReportPage = () => {
    const { isAssessed, core_values, support_values, drivers, qualifiers, themes, date_created, user } = useSelector(store => store.results)
    //todo add dispatch/selector for additional demo information for name and such here
    const demo = user ? {
        name: `${user.first_name} ${user.last_name}`,
        date: new Date(date_created).toLocaleString('en-US', {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        position: user.position,
        years: user.time_at_position
    } : null 
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resultActions.get())
    },[])

    if(isAssessed) {
        return <ResultReport
            coreValues={core_values}
            supportValues={support_values}
            drivers={drivers}
            characterValues={qualifiers}
            themes={themes}
            demo={demo}
        />
    } else {
        return <Loading />
    }
}

export const ResultReport = ({coreValues, supportValues, drivers, characterValues, themes, demo}) => {
    const Logo = getPrintBrandLogo()
    const CoverImg = getBrandCoverImage()

    const popupPrint = async () => {
        await new Promise(resolve => setTimeout(resolve, 1000))
        window.print()
    }

    useEffect(() => {
        popupPrint()
    },[])

    const hasValues = (coreValues || supportValues || drivers || characterValues) ? true : false
    const hasThemes = (themes) ? true : false
    const titleCount = themes.reduce((a, b) => {
        if(b.jobs) a += b.jobs.length
        return a
    }, 0)
    const hasTitle = titleCount ? true : false

    const CoverPage = () => {
        //return <div />
        return (
            <Container style={{pageBreakAfter: "always"}}>
                <div style={{pageBreakAfter: "always"}}>
                <Row><Col style={{padding: '37px'}}>
                  <Row><Col style={styles.colTitle} sm="2">Date: </Col><Col>{demo.date}</Col></Row>
                  <Row><Col style={styles.colTitle} sm='2'>Results For: </Col><Col>{demo.name}</Col></Row>
                  {demo.position && <Row><Col style={styles.colTitle} sm='2'>Job Title: </Col><Col>{demo.position}</Col></Row>}
                  {demo.years && <Row><Col style={styles.colTitle} sm='2'>Time in Job Role: </Col><Col>{demo.years}</Col></Row>}
                </Col></Row>
                    <img src={CoverImg} />
                </div>
            </Container>
        )
    }

    const Header = () => {
        return (
            <Container>
                <div><Logo /></div>
            </Container>
        )
    }

    const Footer = () => {
        return (
            <Container className="page-footer text-center">
                <hr />
                Copyright &copy; {new Date().getFullYear()} {getBrandNameFull()}&trade; All Rights Reserved.
            </Container>
        )
    }

    return (
        <Container>
            <Container style={{padding: "0px"}}>
                <Row style={{width: "100%", margin: "0px", padding: "0px"}} >
                    <Col>
                        <Container>
                            <table id="result-report-table">
                                <thead><tr><td>
                                    <div className="header-space"><Header /></div> 
                                </td></tr></thead>
                                <tbody><tr><td>
                                    <CoverPage />
                                    <br  style={{pageBreakAfter: "always"}}/>
                                    <div style={{margin: '0px'}}>&nbsp;</div>
                                    { hasValues &&
                                        <ResultValues
                                            coreValues={coreValues}
                                            supportValues={supportValues}
                                            drivers={drivers}
                                            characterValues={characterValues}
                                        />
                                    }
                                    { hasThemes &&
                                        <ResultThemes themeList={themes} />
                                    }
                                    {hasTitle &&
                                        <ResultTitles themeList={themes} />
                                    }
                                    <Appendix />
                                    <br />
                                </td></tr></tbody>
                                <tfoot><tr><td>
                                    <div className="footer-space"><Footer /></div>
                                </td></tr></tfoot>
                            </table>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export const ResultReportButton = (props) => {

    const [modal, setModal] = useState(false)

    const toggle = () => {

        if(!modal) {
            //going to display
            ReactGA.pageview('result_report')
        }

        setModal(!modal)
    }

    return (
        <Fragment>
            <Button onClick={toggle} style={{cursor: "pointer"}}>Print Report</Button>
            <Modal isOpen={modal} toggle={toggle} modalClassName="modal-fullscreen">
                <ResultReport {...props} />
            </Modal>
        </Fragment>
    )
}

const styles = {
    colTitle: {
        fontWeight: 'bold',
        color: '#007bff'
    }
}