import config from 'config';
import {Store} from './store'

export const serviceHelpers = {
    getServiceHeaders,
    handleServiceResponse,
    getServiceUrl,
    handleFetchError
};

function getServiceHeaders(includeAuth) {

    const {auth} = Store.getState()

    if (includeAuth && auth && auth.data && auth.data.token) {
        return { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth.data.token
        };
    } else {
        return {
            'Content-Type': 'application/json'
        };
    }
}

function handleServiceResponse(response) {
    return response.text().then(text => {
        
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            let error = {
                code: (data && data.code) || 'error',
                message: (data && data.message) || response.statusText || 'Unkown Error x03391aa00',
                invalid: null
            }

            switch(response.status) {
                case 401:
                    // auto logout if 401 response returned from api 
                    //todo: Add logging for failed               
                    localStorage.removeItem('session');
                    //location.reload(true);
                    break;
                    
                case 400:
                    if(error.code == 'invalid') {
                        error.invalid = ((data && data.errors) || []).reduce((items, i) => {
                            items[i.field] = i.message
                            return items
                        },{})
                    }
                    break;
            }

            console.error(error)

            return Promise.reject(error);
        }

        return data;
    });
}

function getServiceUrl(path, options = {}) {

    let query = Object.keys(options).reduce((result, key) => {
        if(result) result += '&';
        else result = '?';
        result += key +'='+ options[key];
        return result
    }, '')

    return config.apiUrl + path + query;
}

function handleFetchError(err) {
    if(err.code) {
        return Promise.reject(err)
    } else {
        return Promise.reject({code: 'error', message: err.message, invalid: null});
    }
}