import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, CardHeader, CardBody, Form, FormGroup, FormFeedback, Label, Input, Button } from 'reactstrap'
import {schemaProps, FormHandler, formatFunctions} from "../Utils/FormHandler";

import { Loading } from '../Utils/Loading'

import { userActions } from '../../redux/actions'

export const RegisterPage = (props) => {

    const { isLoading, isSuccess, error } = useSelector(store => store.users)
    const dispatch = useDispatch();

    const formSchema = {
        email: {
            value: '',
            [schemaProps.VALIDATOR]: {
                required: true,
                format: formatFunctions.EMAIL
            }
        }
    }

    function handleForm({ email }) {
        dispatch(userActions.register(email))
    }
    const {
        fieldsValue, fieldsError, isDisabled, fieldsValid, fieldsInvalid,
        handleOnChange, handleOnSubmit, handleServiceErrors
    } = FormHandler(formSchema, handleForm, (error && error.invalid) || [])

    const { email } = fieldsValue;

    if(error) {
        handleServiceErrors(error.invalid || [])
    }

    const ErrorDiv = () => { 
        if(error) {
            return <div className="alert alert-danger" role="alert">{error.message || 'Unknown error has occured'}</div>
        } else {
            return <div />;
        }
    }

    if(isLoading) {
        return <Loading />
    } else if(isSuccess) {
        return <Container><Card className="align-self-center"><CardBody>You have signed up and an email has been sent to you with your password</CardBody></Card></Container>
    } else {
        return (
            <Card body>
                <CardBody>
                    <ErrorDiv />
                    <Form onSubmit={handleOnSubmit}>
                        <FormGroup>
                            <Label htmlFor="username">Email</Label>
                            <Input type="text"
                                valid={fieldsValid.email}
                                invalid={fieldsInvalid.email}
                                placeholder="Email"
                                name="email"
                                onChange={handleOnChange}
                                value={email}
                                required />
                                <FormFeedback>{fieldsError.email}</FormFeedback>
                        </FormGroup>
                        <Button type="submit" value="submit" color="primary" disabled={isDisabled}>Register</Button>
                    </Form>
                </CardBody>
            </Card>
        )
    }
    
}