import React, { useState } from "react";
import { useSelector } from 'react-redux'
import { getBrandLogo } from '../Branding'
//import Logo from './logo.svg';
import './App.css';

//nav
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container,Row,Col
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const Header = (props) => {
    const {isAuthenticated} = useSelector(store => store.auth)

    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => setIsOpen(!isOpen);
    const Logo = getBrandLogo()

    const Secured =
        <Navbar>
            <Container>
                <Container style={{padding: "0px"}}>
                    <Row style={{width: "100%", margin: "0px", padding: "0px"}} >
                        <Col>
                            <Container>
                                <NavbarBrand style={{pading: "0px"}}><Logo alt="DreamJob Discovery" /></NavbarBrand>
                                <Nav className="ml-auto" navbar>
                                    <NavItem>
                                        <NavLink href="/logout"><FontAwesomeIcon icon="sign-out-alt" size="lg" />Logout</NavLink>
                                    </NavItem>
                                </Nav>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Navbar>
    
    const Unsecured = 
        <Navbar>
            <Container>
                <Container>
                    <Row>
                        <Col>
                            <Container>
                                <NavbarBrand style={{pading: "0px"}}><Logo alt="DreamJob Discovery" /></NavbarBrand>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Navbar> 

    
    if(window.location.pathname == '/result/report') {
        return <div/>
    }

    return (isAuthenticated) ? Secured : Unsecured
}