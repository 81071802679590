import React, { useState, useCallback, useEffect } from 'react'

import ReactGA from 'react-ga'

import { Container, Row, Col, Button } from 'reactstrap'

import { ResultValues, ResultThemes, ResultNextSteps, ResultTitles, Appendix } from './'

import { getBrandName, getBrandCoverImage } from '../Branding'

class PageNav {
     pages = []

    addPage(path, page, prev, next, print) {
        this.pages.push({
            path,
            page,
            prev,
            next,
            print
        })
    }

    getPage(index) {
        if(index === null || index >= this.pages.length || index < 0) {
            return null
        }

        const cur = this.pages[index] || null
        const p = this.pages[index-1] || null
        const n = this.pages[index+1] || null

        if(!cur) return null

        return {
            path: cur.path,
            page: cur.page,
            prev: p?.prev || '',
            next: n?.next || '',
            print: cur.print || index == this.pages.length - 1 ? 'PRINT YOUR RESULTS' : ''
        }
    }
}

export const ResultPage = ({coreValues, supportValues, drivers, characterValues, themes, demo, onCheckCb}) => {

    const [page, setPage] = useState(null)
    const [prevBuName, setPrevBuName] = useState('')
    const [nextBuName, setNextBuName] = useState('NEXT PAGE')
    const [printBuName, setPrintPage] = useState('')
    const [contentPage, setContentPage] = useState(<div></div>)
    const [pages, setPages] = useState(null)

    const brandName = getBrandName().toUpperCase()
    const dnaImg = getBrandCoverImage()
    
    const valuesPage = useCallback(() => {
        return (
            <ResultValues
                coreValues={coreValues}
                supportValues={supportValues}
                drivers={drivers}
                characterValues={characterValues}
                onCheckCb={onCheckCb} />
        )
    },[coreValues, supportValues, drivers, characterValues])

    const themesPage = useCallback(() => {
        return (
            <ResultThemes themeList={themes} onCheckCb={onCheckCb} />
        )
    },[themes])

    const titlePage = useCallback(() => {
        return (
            <ResultTitles themeList={themes} onCheckCb={onCheckCb} />
        )
    },[themes])

    const nextStepsPage = useCallback(() => {
        return (
            <ResultNextSteps />
        )
    },[])

    const appendixPage = useCallback(() => {
        return <Appendix />
    })

    const introPage = useCallback(() => {
        return (
            <>
                <Row><Col style={{padding: '37px'}}>
                  <Row><Col style={styles.colTitle} sm="2">Date: </Col><Col>{demo.date}</Col></Row>
                  <Row><Col style={styles.colTitle} sm='2'>Results For: </Col><Col>{demo.name}</Col></Row>
                  {demo.position && <Row><Col style={styles.colTitle} sm='2'>Job Title: </Col><Col>{demo.position}</Col></Row>}
                  {demo.years && <Row><Col style={styles.colTitle} sm='2'>Time in Job Role: </Col><Col>{demo.years}</Col></Row>}
                </Col></Row>
                <Row><Col><img className="d-block w-100" src={dnaImg} alt={`${brandName} DNA Profile`} /></Col></Row>
            </>
        )
    },[])

    const calcIsPrintable = useCallback(() => {
        return true
        if(!(coreValues && supportValues && characterValues && drivers && characterValues && themes)) {
            return false;
        }

        return coreValues.some(i => i.selected) &&
            supportValues.some(i => i.selected) &&
            drivers.some(i => i.selected) &&
            characterValues.some(i => i.selected) &&
            themes.some(i => i.selected) &&
            themes.map(i => {
                return i.jobs.some(j => j.selected)
            }).some(i => i)
    }, [coreValues, supportValues, drivers, characterValues, themes])

    useEffect(() => {
        const p = new PageNav()
        p.addPage('/results/intro', introPage, '', '', false)

        if(coreValues || supportValues || drivers || characterValues)
            p.addPage('/results/values', valuesPage, 'PREVIOUS PAGE', `YOUR ${brandName} VALUES`, true)

        if(themes)
            p.addPage('/results/themes', themesPage, 'PREVIOUS PAGE', `YOUR ${brandName} TYPES`, true)

        const titleCount = themes.reduce((a, b) => {
            if(b.jobs) a += b.jobs.length
            return a
        }, 0)
        
        if(titleCount)
            p.addPage('/results/titles', titlePage, 'PREVIOUS PAGE', `YOUR ${brandName} TITLES`, true)

        //p.addPage('/results/next')

        p.addPage('/results/appendix', appendixPage, 'PREVIOUS PAGE', 'APPENDIX', true)

        setPages(p)
        
    }, [coreValues, supportValues, characterValues, drivers, characterValues, themes])

    useEffect(() => {
        const t = (pages === null) ? null : pages.getPage(page)

        if(!t || page <= 0) {
            ReactGA.pageview(`/results/intro`)
            setContentPage(introPage)
            setNextBuName(`VIEW YOUR RESULTS`)
            setPrevBuName('')
            setPrintPage('PRINT YOUR RESULTS')
        } else if(page > 0) {            
            ReactGA.pageview(t.path)
            setContentPage(t.page)
            setNextBuName(t.next)
            setPrevBuName(t.prev)
            setPrintPage(t.print)
        }
    }, [page, pages])

    useEffect(() => {
        setPage(0)
    }, [])

    const PrintButton = useCallback(() => {
        if(!calcIsPrintable()) {
            return <Button 
                    disabled
                    title={`Finish checking off your top choices for Values, ${brandName} Type and Job Titles to activate the Print Function.`}
                    outline color="primary">
                        {printBuName}
                </Button>
        } else {
            return  <a href='/result/report' target="_blank"><Button outline color="primary">{printBuName}</Button></a>
        }
    }, [calcIsPrintable, printBuName])

    return (
        <Container>
            <Row>
                <Col>
                    {contentPage}
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{paddingTop: '25px', paddingBottom: '35px'}}>
                    {
                        printBuName && 
                        <PrintButton />
                    }
                    {' '}
                    {
                        prevBuName &&
                    <Button outline color="primary" onClick={() => setPage(page - 1)}>{prevBuName}</Button>
                    }
                    {' '}
                    {
                        nextBuName && 
                        <Button outline color="primary" onClick={() => setPage(page + 1)}>{nextBuName}</Button>
                    }
                </Col>
            </Row> 
        </Container>
    )
}

const styles = {
    colTitle: {
        fontWeight: '800',
        color: '#007bff'
    }
}