import React, { Fragment, useCallback } from 'react'

import ReactGA from 'react-ga'

import {schemaProps, FormHandler} from "../Utils/FormHandler";

import { Container, Row, Col, Button, Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap'

export const DemographicPage = ({submitCb, error, prevCb, answers}) => {
    const formSchema = {
        career_status: {
            value: answers.career_status,
            [schemaProps.VALIDATOR]: {
                required: true,
            }
        },
        gender: {
            value: answers.gender,
            [schemaProps.VALIDATOR]: {
                required: true
            }
        },
        generation: {
            value: answers.generation,
            [schemaProps.VALIDATOR]: {
                required: true
            }
        },
        education: {
            value: answers.education,
            [schemaProps.VALIDATOR]: {
                required: true
            }
        }
    }

    const {
        fieldsValue, fieldsError, isDisabled, isError,
        handleChange, handleOnSubmit, handleServiceErrors
     } = FormHandler(formSchema, submitCb)
 
    if(error) {
        handleServiceErrors(error.errors || [])
    }
 
    const ErrorDiv = useCallback(() => {
        if(error) {
            return <div className="alert alert-danger" role="alert">{error.message || 'Unknown error has occured'}</div>
        } else {
            return <Fragment />;
        }
    },[error])

    const Answer = useCallback(({field, value, label}) => {
        return (
            <FormGroup check>
                <Label check>
                    <Input
                        type="radio"
                        onChange={_ => handleChange(field, value)}
                        checked={fieldsValue[field] == value}
                    />{' '}
                    {label}
                </Label>
            </FormGroup>
        )
    }, [handleChange, fieldsValue])

    const Question = useCallback(({question, field, answers}) => {

        const alertClass = (fieldsError[field]) ? 'alert-danger' : ''

        return(
            <FormGroup className={alertClass} style={{backgroundColor: "#D8E2F3", padding: "10px", border: "1px #31538F solid"}}>
                <h2>{question}</h2>
                {
                    answers.map((data, id) => {
                        return <Answer
                            key={`${field}_answer_${id}`}
                            field={field}
                            value={data.value}
                            label={data.text}
                        />
                    })
                }
                <FormFeedback>{fieldsError[field]}</FormFeedback>
            </FormGroup>
        )
    }, [fieldsError])

    return (
        <Container>
            <Row>
                <Col>
                    <p>Almost done! Just four more questions to help us serve you better:</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ErrorDiv />
                    <Form onSubmit={handleOnSubmit}>

                        <Question
                            field="career_status"
                            question="If you had to choose just one, which of the following best describes your situation?"
                            answers={[
                                {value: "starter", text: <Fragment><b>Starting: </b> Trying to figure out what career track to choose</Fragment>},
                                {value: "seeker", text: <Fragment><b>Seeking: </b> Currently looking for employment</Fragment>},
                                {value: "striver", text: <Fragment><b>Striving: </b> Seeking to advance your career</Fragment>},
                                {value: "shifter", text: <Fragment><b>Shifting: </b> Planning your professional pivot into a different career</Fragment>},
                                {value: "stuck", text: <Fragment><b>Stuck: </b> Desperate to get unstuck from a job you don't enjoy</Fragment>},
                                {value: "sequel", text: <Fragment><b>Senior: </b> Desiring a final encore career before your retirement</Fragment>}
                            ]}
                        />

                        <Question
                            field="gender"
                            question="Which best describes you?"
                            answers={[
                                {value: "male", text: "Man"},
                                {value: "female", text: "Woman"},
                                {value: "not answered", text: "Prefer not to say"}
                            ]}
                        />

                        <Question
                            field="generation"
                            question="Which best describes your generation?"
                            answers={[
                                {value: "boomer", text: "Baby Boomer (Born 1946-1964)"},
                                {value: "gen x", text: "Generation X (Born 1965-1980)"},
                                {value: "millennial", text: "Millennial (Born 1981-1996)"},
                                {value: "gen z", text: "Generation Z (Born 1997-2012)"},
                                {value: "not answered", text: "Prefer not to say"}
                            ]}
                        />

                        <Question
                            field="education"
                            question="What is the highest level of degree you’ve received?"
                            answers={[
                                {value: "no diploma", text: "Less than a high school diploma"},
                                {value: "diploma", text: "High school diploma or equivalent (e.g. GED)"},
                                {value: "trade", text: "Trade/Technical certificate"},
                                {value: "some college", text: "Some college, but no degree"},
                                {value: "college", text: "College degree"},
                                {value: "graduate or higher", text: "Graduate degree or higher"},
                                {value: "not answered", text: "Prefer not to say"}
                            ]}
                        />

                        {
                            isError &&
                            <div className="alert-danger" style={{marginTop: "10px", marginBottom: "5px"}}>
                                Oops! One or more questions were skipped
                            </div>
                        }
                        <Row>
                            <Col className="text-center" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                                <Button outline color="primary" onClick={prevCb}>{'< '}PREVIOUS PAGE</Button>
                                <Button outline type="submit" value="submit" color="primary">NEXT PAGE{' >'}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row> 
        </Container>
    )
}