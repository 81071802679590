import React, { useState } from 'react'
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import ReactGA from 'react-ga'

import { LoginPage, RegisterPage, PasswdResetPage, SignupPage } from './'
import { allowSignup } from '../Branding'

import classnames from 'classnames';

export const AuthNavPage = (props) => {
    
    const hasRegister = (props.location.pathname == '/login/test_auth123') ? true : false;
    const canSignup = allowSignup()

    const [activeTab, setActiveTab] = useState('login');

    const toggle = tab => {
        ReactGA.pageview(`/auth/${tab}`)
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <Container style={{marginBottom: "60px"}}>
            <Row>
                <Col xs="12" sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'login' })}
                                onClick={() => { toggle('login'); }}>Login</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'pwreset' })}
                                onClick={() => { toggle('pwreset'); }}>Password Reset</NavLink>
                        </NavItem>
                        {
                            canSignup &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'signup' })}
                                    onClick={() => { toggle('signup'); }}>Sign Up</NavLink>
                            </NavItem>
                        }
                        {
                            hasRegister && 
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'register' })}
                                    onClick={() => { toggle('register'); }}>Register</NavLink>
                            </NavItem>
                        }
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col xs="12" sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="login"><LoginPage /></TabPane>
                        <TabPane tabId="pwreset"><PasswdResetPage /></TabPane>
                        <TabPane tabId="signup"><SignupPage /></TabPane>
                        <TabPane tabId="register"><RegisterPage /></TabPane>
                    </TabContent>
                </Col>
            </Row>
        </Container>
    )
    
}