import React, { useState, useEffect, useRef } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import ReactGA from 'react-ga'

import { Row, Col, Progress, Label, Button, Container } from 'reactstrap'

import { Loading } from '../Utils/Loading'

import { FinalPage } from './FinalPage'

import { assessmentActions, resultActions, demographicActions } from '../../redux/actions'

import { getBrandName, GetBrandedPage, allowDemographics } from '../Branding'

import {  DemographicPage } from '../Demographic'

import scaleImg from '../../assets/scale_99.png'

const QuestionsComponent = ({focusId, questions, answerValues, answerClasses, updateAnswerValue}) => {
    if(!questions) {
        return <></>
    }

    try {
        return questions.map((item, i) => {
            return <QuestionItem 
                key={`question_item_${item.id}`}
                id={item.id}
                question={item.Question.description}
                rowBg={((i ++) % 2) ? 'transparent' : '#D8E2F3'}
                setFocus={item.id == focusId}
                updateAnswerValue={updateAnswerValue}
                answer={answerValues[item.id]}
                initClass={answerClasses[item.id]} />
        })
    } catch(e) {
        console.error(e)
        return <div>An unkown error has occured</div>
    }
}

const QuestionItem = ({id, rowBg, question, setFocus, answer, initClass, updateAnswerValue}) => {
    const itemBg = (initClass== 'alert-danger') ? '#F8D7DA' : rowBg;
    return (
        <Row style={{backgroundColor: itemBg, paddingTop: "10px", border: "1px #31538F solid"}}
            className={'answer-row ' + initClass}>
            <Col sm="12" lg="6">{question}</Col>
            <Col sm="12" lg="6">
                <Row noGutters>
                    {[{l: 'Never', v: 1}, {l: 'Rarely', v: 2}, {l: 'Depends', v: 3}, {l: 'Often', v: 4}, {l: 'Always', v: 5}].map((i) => {
                        return (<QuestionAnswerItem
                            key = {`question_item_answer_${id}_${i.v}`}
                            label={i.l}
                            value={i.v}
                            name={id}
                            checked={answer == i.v}
                            onChange={updateAnswerValue}
                            setFocus={setFocus && i.v === 3}
                        />)
                    })}
                </Row>
            </Col>
        </Row>
    )
}

export const QuestionAnswerItem = ({name, label, value, checked, onChange, setFocus}) => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef.current && setFocus) {
        inputRef.current.focus();
      }
    }, [setFocus]);

    return (
        <Col className="text-center">
            <Label style={{display: 'block', padding: '0px'}}>
            <input className="mx-auto" style={{position: 'static', display: 'block'}}
                type="radio"
                tabIndex={value}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                ref={inputRef}
                />
            {label}</Label>
        </Col>
    )

}

export const AssessmentPage = () => {
    const [assessments, demographics] = useSelector(store => {return [store.assessments, store.demographics]})
    const { isAssessed } = useSelector(store => store.results)

    const {questions} = assessments
    const demoAnswers = demographics.data

    const error = assessments.error || demographics.error || null
    const isLoading = assessments.isLoading || demographics.isLoading || false
    const demographicProcessed = demographics.isProccessed || !allowDemographics()

    const [page, setPage] = useState(null)
    const [finalPage, setFinalPage] = useState('q')
    const [disableBu, setDisableBu] = useState({next: true, prev: true})
    const [nextBuName, setNextBuName] = useState('Next')
    const [isFinal, setIsFinal] = useState(false)
    const [answerValues, setAnswerValues] = useState(null)
    const [answerClasses, setAnswerClasses] = useState()
    const [initPage, setInitPage] = useState(false)
    
    const dispatch = useDispatch();

    const brandName = getBrandName()
    const HomePage = GetBrandedPage({pageName: 'home'})

    //Initial page load
    useEffect(() => {
        //console.error('Initialize page (1 time)')
        setPage(false)
    },[])

    useEffect(() => {

        ReactGA.pageview(`/assessment/${page}`)

        //Safari on IOS doesn't scroll up after inital page
        setTimeout(() => {
            window.scrollTo(0,0), 100
        })
    },[initPage])

    useEffect(() => {
        if(!questions) {
            return;
        }
        
        if(assessments.isLoading) {
            console.error("Page is in a loading status, shouldn't execute here")
            return;
        } else if(!error && questions) {
            if(answerValues && questions.questions && Object.keys(answerValues) == questions.questions.map(item => item.id)) {
                return;
            }

            //Update data
            //console.log('starting answer update')
            const {values, classes} = questions.questions.reduce((data, item) => {
                data.values[item.id] = item.value
                data.classes[item.id] = (item.value < 1 || item.value > 5) ? 'item-row-init' : 'alert-success'
                return data
            }, {values: {}, classes: {}})
            setAnswerValues(values)
            setAnswerClasses(classes)
        }

        //After initial load, sync page with current page
        if(page == false && questions.page_current) {
            setPage(questions.page_current)
        }

    },[questions])

    useEffect(() => {
        if(questions) {

            //Handle button states
            if(questions.page_total > 0) {
                //console.log('--------- updating button states -----------')
                //console.log(`page: ${page} - questions.page_total: ${questions.page_total}`)
                setDisableBu({
                    prev: !(page > 1),
                    next: (page > questions.page_total + 2)
                })
            }

            //Handle final question state (finalize assessment)
            if(page > questions.page_total) {
                //console.log('---------8 set to final 8------------')
                setIsFinal(true)
                if(page == questions.page_total + 1) {
                    if(allowDemographics()) {
                        setNextBuName('NEXT PAGE >')
                        setFinalPage('demo')
                    } else {
                        setPage(questions.page_total + 2)
                    }
                } else if(!demographicProcessed && !isLoading) {
                        setPage(questions.page_total + 1)
                } else if (page > questions.page_total + 2) {
                        setPage(questions.page_total + 2)
                } else {
                    setNextBuName('GET YOUR RESULTS')
                    setFinalPage('finsh')
                }
            } else {
                //console.log('---------8 set to next 8------------')
                setNextBuName('NEXT PAGE >')
                setFinalPage('q')
                setIsFinal(false)
            }
        }
        
        if(!questions || (questions.page_current != page && questions.page_total >= page - 1)) {
            if(!isLoading) {
                if(!page) {
                    dispatch(assessmentActions.getPage(page))
                } else {

                    ReactGA.pageview(`/assessment/${page}`)
            
                    const submitValues = Object.keys(answerValues).reduce((result, id) => {
                        //console.log(`id: ${id} value: ${answerValues[id]}`)
                        if(answerValues[id] > 0) {
                            result[id] = answerValues[id]
                        }
                        return result
                    }, {})

                    if(submitValues != {}) {
                        //console.error(submitValues)
                        const newPage = (page > questions.page_total) ? questions.page_total : page;
                        dispatch(assessmentActions.updatePage(newPage, submitValues))
                    }
                }
            }
        }
    },[page])

    const updateAnswerValue = ({target}) => {
        //console.log('---------- updating answers -----------')
        //console.log(target)
        setAnswerValues(prevState => ({ ...prevState, [target.name]: target.value }))
        setAnswerClasses(prevState => ({ ...prevState, [target.name]: 'alert-success' }))
    }

    const submitPage = (newPage) => {
        //console.log(newPage)
        //console.log(`final: ${isFinal}, newPage: ${newPage}, page: ${page}`)
        if(!isFinal || (newPage < page && newPage > 0)) {
            let isInvalid = false
            if(newPage > page) { //Next, need to validate they answered all questions
                Object.keys(answerValues).map(id => {
                    if(answerValues[id] < 1 || answerValues[id] > 5) {
                        setAnswerClasses(prevState => ({ ...prevState, [id]: 'alert-danger' }))
                        isInvalid = true
                    } else {
                        setAnswerClasses(prevState => ({ ...prevState, [id]: 'alert-success' }))
                    }
                })
            }

            if(!isInvalid) {
                setPage(newPage)
            }
        } else if(isFinal && newPage > questions.page_total) {
            if(newPage < questions.page_total + 3) {
                setPage(newPage)
            } else {
                dispatch(resultActions.create())
            }
        }
    }

    const getNextErrorId = () => {
        const e = Object.keys(answerClasses)
            .filter(i => answerClasses[i] == 'alert-danger')
        return (e.length) ? e[0] : false;
    }

    const updateDemoQuestions = (data) => {
        dispatch(demographicActions.save(data))
        submitPage(page + 1)
        
    }

    const ErrorDiv = () => { 
        if(error) {
            return <div className="alert alert-danger" role="alert">{error.message || 'Unknown error has occured'}</div>
        } else {
            return <div />;
        }
    }
    
    const PageContent = () => {
        if(finalPage == 'demo' && allowDemographics()) {
            ReactGA.pageview('/assessment/demographics')

            return (
                <>
                    <Row>
                        <Col>
                            <DemographicPage
                                submitCb={updateDemoQuestions}
                                prevCb={() => submitPage(page - 1)}
                                error={error}
                                answers={demoAnswers}
                            />
                        </Col>
                    </Row>
                </>
            )
            
        } else {
            ReactGA.pageview('/assessment/congrats')

            return (
                <>
                    <Row>
                        <Col xs="12" sm={{size: 10, offset: 1}} md={{size: 8, offset: 2}}>
                            <FinalPage />
                        </Col>
                    </Row>
                </>
            )

        }
    }

    if(isAssessed) {
        return <Route><Redirect to='/results' /></Route>
    }
    
    if(!initPage && page == 1 && 0 === Object.keys(answerValues).reduce((result, id) => {
        return result += answerValues[id]
    }, 0)) {

        ReactGA.pageview('/assessment/intro')
            
        return (
            <Container>
                <Row>
                    <Col>
                        <Container>
                            <HomePage />
                            <div style={{marginTop: "50px", marginBottom: "50px"}} className="text-center">
                                <Button color="primary" onClick={() => setInitPage(true)}>START YOUR {brandName.toUpperCase()} ASSESSMENT</Button>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
        )
    } else if(isLoading || !questions || !answerValues || !answerClasses) {
        if(error) {
            return <ErrorDiv />
        } else {
            return <Loading />
        }
    } else {
        const perFinished = Math.ceil(page / questions.page_total * 100)
        return(
            <Row>
                <Col>
                    <Container>
                        {
                            !isFinal && 
                            <Row>
                                <Col>
                                    <h1>Your {brandName} Assessment&trade;</h1>
                                    {
                                        !isFinal &&
                                        <>
                                            <h2>
                                                Rate how TRUE each of the following statements is for you in your life right now.
                                            </h2>
                                            <Row style={{marginTop: 10, marginBottom: 10}}>
                                                <Col style={{padding: 0}} xs="12" sm="9" md="7" lg="5"><img className="d-block w-100" src={scaleImg} alt="Rating Scale" /></Col>
                                                <Col></Col>
                                            </Row>
                                        </>
                                    }
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <Container className="question-container">
                                    {isFinal && <PageContent />}
                                    {!isFinal && <QuestionsComponent
                                        questions={questions.questions}
                                        answerValues={answerValues}
                                        answerClasses={answerClasses}
                                        updateAnswerValue={updateAnswerValue}
                                        focusId={getNextErrorId()} />
                                    }
                                </Container>
                            </Col>
                        </Row>
                        {
                            !isFinal && 
                            <Row>
                                <Col className="text-center border-dark" style={{paddingTop: '15px'}}> 
                                    <div className="text-center">{(perFinished > 100) ? 100 : perFinished}%</div>                      
                                    <Progress value={page} max={questions.page_total} />
                                </Col>
                            </Row>
                        }
                        {
                            finalPage != 'demo' &&
                            <Row>
                                <Col className="text-center" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                                    {
                                        getNextErrorId() &&
                                        <div className="alert-danger" style={{marginTop: "10px", marginBottom: "5px"}}>
                                            Oops! One or more questions were skipped
                                        </div>
                                    }
                                    {
                                        !disableBu.prev &&
                                        <Button outline color="primary" onClick={() => submitPage(page - 1)} disabled={disableBu.prev}>{'< '}PREVIOUS PAGE</Button>
                                    }
                                    {' '}
                                    {
                                        !disableBu.next &&
                                        <Button outline color="primary" onClick={() => submitPage(page + 1)} disabled={disableBu.next}>{nextBuName}</Button>
                                    }
                                </Col>
                            </Row>
                        }

                    </Container>
                </Col>
            </Row>
        )
    }
}