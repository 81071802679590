import { resultConstants as constants } from '../constants';
import { reducerHelpers } from '../helpers';

export function results(state = {
    isLoading: false,
    error: null,
    isAssessed: false,
    core_values: null,
    support_values: null,
    drivers: null,
    qualifiers: null,
    themes: null,
    lastRequest: null,
    date_created: null,
    user: null
}, action) {
  switch (action.type) {

    case constants.RESULT_REQUEST:
        return {...state,
            isLoading: true,
            lastRequest: reducerHelpers.initRequestTime()
    };
    case constants.RESULT_SUCCESS:

        reducerHelpers.processSuccessEvent('Results', state.lastRequest)

        return {...state,
            isLoading: false,
            error: null,
            isAssessed: (action.data !== ""),
            core_values: action.data.core_values || [],
            support_values: action.data.support_values || [],
            drivers: action.data.drivers || [],
            qualifiers: action.data.qualifiers || [],
            themes: action.data.themes || [],
            date_created: action.data.date_created || '',
            user: action.data.user || {first_name: '', last_name: '', position: '', time_at_position: ''}
    };

    case constants.RESULT_FAILURE:

        reducerHelpers.processFailureEvent('Results', state.lastRequest, action.error)

        return {...state,
            isLoading: false,
            error: action.error
    };
    
    default:
        return state
  }
}