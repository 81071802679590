import { useState, useEffect, useCallback } from 'react';

export const schemaProps = {
    VALUE: 'value',
    ERROR: 'error',
    VALIDATOR: 'validator'
}

export const formatFunctions = {
    EMAIL: value => /^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/.test(value),
    PHONE: value => /^[a-zA-Z]+$/.test(value),
    DATE: value => /^[a-zA-Z]+$/.test(value),
}

export function FormHandler(schema, submitCb, serviceInvalid=[]) {

    //Object of field:value pairs {field1: "value"...}
    const [fieldsValue, setFieldsValue] = useState(getInitialFromSchema(schema, schemaProps.VALUE));

    //Object of field:error pairs {field1: "value"...}
    const [fieldsError, setFieldsError] = useState(getInitialFromSchema(schema, schemaProps.ERROR));

    //Object of field:validator_obj pairs {field1: {}}...}
    const [fieldsValidator, setFieldsValidator] = useState(getInitialFromSchema(schema, schemaProps.VALIDATOR));

    //Object of field:dirty pairs {field1: bool...}
    const [fieldsDirty, setFieldsDirty] = useState(getInitialFromSchema(schema, false));

    //Object of field:invalid pairs {field1: bool...}
    const [fieldsInvalid, setFieldsInvalid] = useState(getInitialFromSchema(schema, false));

    //Object of field:valid pairs {field1: bool...}
    const [fieldsValid, setFieldsValid] = useState(getInitialFromSchema(schema, false));

    //If submit should be disalbed
    const [isDisabled, setIsDisabled] = useState(true);

    //If form is dirty (content changed from last)
    const [isDirty, setIsDirty] = useState(false);

    //If form is dirty (content changed from last)
    const [isError, setIsError] = useState(false);

    //Initialized is used to ensure we don't render again internally
    const [isInit, setIsInit] = useState(false);

    function isBool(value) {
        return typeof value === 'boolean';
    }
    
    function isObject (value) {
        return typeof value === 'object' && value !== null;
    }
    
    function isRequired (value, isRequired) {
        (!value && isRequired)
    }

    function isFunction(value) {
        return typeof value === "function"
    }
    
    function getInitialFromSchema(formSchema, prop) {
        return Object.keys(formSchema).reduce((fields, key) => {
            
            let value = ''

            switch(prop) {
                case schemaProps.VALUE:
                case schemaProps.ERROR:
                    value = formSchema[key][prop] || '';
                    break;
    
                case schemaProps.VALIDATOR:
                    value = getInitialValidator(formSchema[key][prop] || {})
                    break;
    
                default:
                    value = prop;
                    break;
            }
                      
            fields[key] = value;
    
            return fields;

        }, {});
    }

    function getInitialValidator (validator) {
        return {
            min: validator.min || false,
            max: validator.max || false,
            required: validator.required || false,
            format: validator.format || false,
            format_error: validator.format_error || false
        }
    }

    // Set a specified field's value and update dirty
    const setFieldValue = ({ name, value }) =>
        setFieldsValue(prevState => ({ ...prevState, [name]: value }));

    // Set a specified field's error
    const setFieldError = ({ name, error }) =>
        setFieldsError(prevState => ({ ...prevState, [name]: error }));

    // Set a specified field's dirty status
    const setFieldDirty = ({ name, dirty }) =>
        setFieldsDirty(prevState => ({ ...prevState, [name]: dirty }));

    // Validate specified field
    const validateField = useCallback(
        (name, value) => {
            const validator = fieldsValidator[name] || false;
            
            if(!validator) {
                console.error('Validator does not exist for field ' + name);
                return;
            }

            let error = '';

            if(!value && validator.required) {
                error = 'This is a required field'
            } else if(validator.min && validator.min > value.length) {
                error = 'This field must be at least ' + validator.min + 'characters'
            } else if(validator.max && validator.max < value.length) {
                error = 'This field must be less ' + validator.max + 'characters'
            } else if(validator.format && !validator.format(value)) {
                error = validator.format_error || 'Format is invalid for this field'
            }

            return error;
        },
        [fieldsValue, fieldsValidator]
    );

    // set isDirty if any fields are dirty
    useEffect(() => {
        setIsDirty(validateDirtyState());
    }, [fieldsDirty]);

    // set isDisabled if isDirty and errors exist
    useEffect(() => {
        if (isDirty) {
            setIsDisabled(validateErrorState());
        }
    }, [fieldsError, isDirty]);

    // set isError if any fields contain errors
    useEffect(() => {
        setIsError(validateErrorState());
    }, [fieldsError]);

    // Evaluate if any fields are dirty
    const validateDirtyState = useCallback(
        () => Object.values(fieldsDirty).some(dirty => dirty),
        [fieldsDirty]
    );

    // Evaluate if errors exist
    const validateErrorState = useCallback(
        () => Object.values(fieldsError).some(error => error),
        [fieldsError]
    );

    //Force validation of all fields (a little hacky)
    const validateAllFields = useCallback(() => {
        return Object.keys(fieldsValue).map((name) => {
            const error = validateField(name, fieldsValue[name])
            if(error) {
                setFieldError({ name, error });
            }
            return error
        }).some(error => error)
    }, [fieldsValue, fieldsError])

    // Event handler for form onSubmit events
    const handleOnSubmit = useCallback(
        event => {
            if(event) {
                event.preventDefault();
            }

            validateAllFields()

            if (!validateErrorState() && !validateAllFields()) {
                setFieldsDirty(getInitialFromSchema(schema, false))
                submitCb(fieldsValue)
            }
        },
        [validateErrorState, fieldsValue, fieldsError]
    );

    // Manual handler for field change events
    const handleChange = useCallback((name, value) => {

            const error = validateField(name, value);
            const dirty = !(fieldsValue[name] == value);

            setFieldValue({ name, value });
            setFieldError({ name, error });
            setFieldDirty({ name,  dirty });
            
            // todo: add check for value.length || fieldIsDirty
            setFieldsValid(prevState => ({ ...prevState, [name]: (value.length && !error.length) ? true : false }));
            setFieldsInvalid(prevState => ({ ...prevState, [name]: (value.length && error.length) ? true : false }));
        },
        [validateField, fieldsValue]
    );

    // Event handler for field onChange events
    const handleOnChange = useCallback(event => {
        handleChange(event.target.name, event.target.value)
        
    }, [handleChange]);

    //Ensure everything is accurate based on inital data

    const handleServiceErrors = useCallback(
        errors => {
            if(typeof errors == 'object') {
                Object.keys(errors).forEach(field => {
                    if(!fieldsInvalid[field]) {
                        setFieldError(field, errors[field])
                        setFieldsValid(prevState => ({ ...prevState, [field]: false}));
                        setFieldsInvalid(prevState => ({ ...prevState, [field]: true}));
                    }
                })
            }
        },[fieldsError]
    )

    return {
        fieldsValue,            // Key: Value pairs of field values
        fieldsError,            // Key: Value pairs of field errors
        fieldsDirty,            // Key: Value pairs if field is dirty (new data)
        fieldsValid,            // Key: Value pairs if field is valid
        fieldsInvalid,          // Key: Value pairs if field is invalid
        isDisabled,             // If form submit should be disabled
        isDirty,                // If form data is dirty (one or more fields)
        isError,                // If form data contains errors (one or more fields)
        handleOnChange,         // Handle on change event for a field onChange={handleOnChange}
        handleChange,           // Handle on change event for a field onClick={_ => handleChange('field', <value>)}
        handleOnSubmit,         // Handle on submit event for form onSubmit={handleOnSubmit}
        handleServiceErrors     // Handle errors returned by service
    };
    
}