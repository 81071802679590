import React from 'react'

import { Container } from 'reactstrap'

import ReactGA from 'react-ga'

import jobFulfillmentVenn300 from './JobFulfillmentVenn300.png'

import jptiCta from './jpti_cta.png'

const typesDesc = [
    {
        name: 'Analyzing or Calculating',
        desc: 'A job function where you get to calculate or interpret the meaning of numbers or data.'
    },
    {
        name: 'Artistic Creation',
        desc: 'A job function where you get to be creative from an artistic perspective.'
    },
    {
        name: 'Chronicling or Documenting',
        desc: 'A job function where you get to record and report facts, observations, or conclusions about people, events, places, or processes. '
    },
    {
        name: 'Coaching or Mentoring',
        desc: 'A job function where you get to work with individuals to improve their performance and move them closer to becoming the best they can be in a specific activity or area of their life.'
    },
    {
        name: 'Competition',
        desc: 'A job function where you get to pit your skills or knowledge against those of others in a quest to be the best, because you love to win.'
    },
    {
        name: 'Designing',
        desc: 'A job function where you get to be a creator of things from the perspective of form, function, and beauty. '
    },
    {
        name: 'Entertaining',
        desc: 'A job function where you get to perform and connect with an audience on an emotional level to make them feel happiness, sadness, love, fear, anxiety, excitement, or awe. '
    },
    {
        name: 'Evaluating or Judging',
        desc: 'A job function that involves a process of evaluating or judging to determine quality, worth, appropriateness, or best option.        '
    },
    {
        name: 'Exploring or Adventuring',
        desc: 'A job function where you get to be outdoors and go boldly where few have gone before.'
    },
    {
        name: 'Fabricating or Fixing',
        desc: 'A job function where you get to work with your hands and use tools to either build, install, assemble, repair, or restore things.'
    },
    {
        name: 'Healing or Caring',
        desc: 'A job function where you get to care for the well-being of people, plants, or animals.'
    },
    {
        name: 'Helping or Serving',
        desc: 'A job function where you get to be helpful and supportive in attending to the needs of others.'
    },
    {
        name: 'Improving or Perfecting',
        desc: 'A job function where your primary focus is on making improvements to products, services, systems, procedures, processes, results, or relationships.'
    },
    {
        name: 'Investigating or Fact Finding',
        desc: 'A job function that involves the process of fact finding and deductive reasoning to plot strategy or solve mysteries and complex problems.'
    },
    {
        name: 'Leading or Controlling',
        desc: 'A job function where you get to be in charge and in control of situations and their outcomes.'
    },
    {
        name: 'Organizing or Coordinating',
        desc: 'A job function where you get to organize and coordinate, itineraries, events, people, projects, or information.'
    },
    {
        name: 'Persuading',
        desc: 'A job function where you get to influence people in terms of their beliefs, attitudes, motivations, or behaviors.'
    },
    {
        name: 'Science',
        desc: 'A job function that requires an adherence to the scientific method while conducting experiments to validate a hypothesis.'
    },
    {
        name: 'Socializing',
        desc: 'A job function where you get to work closely with others because you thrive on the camaraderie and sense of community that happens with social interaction.'
    },
    {
        name: 'Storytelling',
        desc: 'A job function where you create and communicate stories that connect on an emotional level to make audiences feel happiness, sadness, love, fear, anxiety, excitement, or awe.'
    },
    {
        name: 'Teaching or Training',
        desc: 'A job function where you get to educate people with knowledge or how-to skills that were previously unknown to them.'
    }
]


export const ThemesPage = ({ThemeItems, themeCount, themeCountText}) => {

    return (
        <Container>
            <h1>Your JobPassion Types&trade;</h1>
            <p>
                When you think about it, activities you feel deeply passionate about doing are ones that fill you
                with a sense of joy, meaning, and fulfillment.
            </p>
            <p>
                This is how you should expect to feel when your job is in direct alignment with your Core and
                Supporting values. That's why we use these values to identify the different job functions you
                may enjoy - your Job Passion Types.
            </p>
            <p>
                There are 21 Job Passion Types. Here is a brief description of each in alphabetical order:
            </p>
            <ol style={Styles.typeList}>
                {typesDesc.map((i, key) => {
                    return (<li key={`thtdes${key}`} style={Styles.typeListItem}>
                        <strong style={{fontWeight: 800}}>{i.name}</strong><br />
                        {i.desc}
                    </li>)
                })}
            </ol>

            <p>
                Not all 21 Job Passion Types will apply to you because not all will be job functions that
                you enjoy doing.
            </p>
            <p>
                Here are the Job Passion Types that you are most likely to enjoy based on how directly
                they align with your Core values and Supporting values.
            </p>

            <ThemeItems />

            <h2>Summary</h2>
            <p>
                The job functions that you are most likely to enjoy are your Job Passion Types. The
                job descriptions created by your employer will determine how many of your Job Passion
                Types you get to apply in your role and how often you get to use them. The more time
                you can spend doing work that involves your Job Passion Types, the happier and more
                productive you will likely become in your role.
            </p>

            <h2>The Job Fulfillment Factor</h2>
            <p>
                Now that you know the values that define the "true you", and your Job Passion Types,
                you have important clues that can help you find job fulfillment.
            </p>
            <p>
                The importance of job fulfillment extends far beyond personal satisfaction. Extensive
                research underscores the significance of job fulfillment as a key factor in driving
                employee motivation and engagement.
            </p>
            <p>
                When employees find their work genuinely fulfilling, they show increased commitment
                and loyalty to their organization, which, in turn, increases their productivity. This
                increased productivity not only enhances the performance of employees but also translates
                into tangible benefits for the organization, including lower turnover rates, reduced
                absenteeism, and higher profitability.
            </p>
            <p>
                Fulfilled employees are more likely to engage in creative problem-solving, contribute to
                a positive work environment, and advocate for their company, which can enhance the
                organization's reputation and attract top talent. Prioritizing job fulfillment can also
                lead to better customer satisfaction and loyalty as engaged employees are more likely to
                deliver superior customer service.
            </p>
            <p>
                Therefore, fostering an environment that promotes job fulfillment is not merely aspirational;
                it is a strategic necessity that can drive organizational success. By pursuing job fulfillment,
                employees and employers alike can forge a more dynamic, productive, and sustainable future.
            </p>
            <p>
                You will feel job fulfillment whenever your work provides you with enjoyment, meaning, and
                engagement. This will happen whenever your skills, passions, and purpose overlap.
            </p>

            <img className="img-fluid mx-auto my-auto d-block w-75" style={{ paddingBottom: "20px" }} src={jobFulfillmentVenn300} alt="Job fulfillment" />

            <p>
                <strong style={{fontWeight: 800}}>Enjoyment:</strong> Many people move up in their careers by using the skills they
                learn and get good at. Although this guarantees that they will do a good job, it doesn't
                necessarily mean that they will enjoy their work. Now that you know your Job Passion Types,
                you'll easily be able to identify which of your current skills give you enjoyment and which
                ones don't. The more skills that align with your Job Passion Types the more enjoyment you'll
                feel from the work you do. 
            </p>
            <p>
                <strong style={{fontWeight: 800}}>Meaning:</strong> If you want your job to have meaning, you need to have a sense of
                purpose when you use your skills. People often feel like they have a purpose in life because
                they believe in the values and beliefs that guide the company they work for. Now that you know
                what your values are, you can figure out how well they match up with the values of your employer
                and how that can provide meaning for you.
            </p>
            <p>
                <strong style={{fontWeight: 800}}>Engagement:</strong> When you use skills that are in line with both your passions and
                your purpose, you'll be so excited and involved in your work that you might forget how much time
                has passed. It's how an athlete feels when they're "in the zone." This is the point at which you
                will finally feel fulfillment from your work. 
            </p>

            <h2>Your Next Steps to Finding Fulfillment</h2>
            <p>
                <strong style={{fontWeight: 800}}>Step 1:</strong> Together with your managers, figure out which of the skills you currently
                have are used in each of the job functions that are your Job Passion Types. Going forward, you
                should try to use those skills as much as possible and stay away from the ones you don't like. Also identify other skills you
                could learn that would help you excel even more when doing work in your Job Passion Types. Doing this will provide you  with an
                arsenal of passion-based skills that you can count on to give you enjoyment at work. 
            </p>
            <p>
                <strong style={{fontWeight: 800}}>Step 2:</strong> Talk to your managers about how to incorporate as many of your Job Passion
                Types as possible into your role. If you can be doing work that involves your Job Passion Types at
                least 65% of the time, you will feel more passionately engaged. If your employer can't make these
                changes to your job description, perhaps they can help you transition into other roles within the
                company that would be a better fit for your Job Passion Types.
            </p>
            <p>
                <strong style={{fontWeight: 800}}>Step 3:</strong> Have an open and honest discussion with your managers and teams about what
                values are most important to you and gain clarity on how they align with the company's culture and
                objectives. Achieving alignment between the values that are most important to you and what your
                company values doesn't mean everything has to match up perfectly. Instead, it's more about
                understanding where significant overlaps exist and where differences can be respected or even
                leveraged for mutually beneficial growth. 
            </p>
            <p>
                <strong style={{fontWeight: 800}}>Step 4:</strong> Work with your managers to find projects, teams, or roles within your
                organization that resonate with your values. This alignment can provide a more fulfilling and
                engaging work experience that benefits both you and the company.
            </p>
                <div style={{marginTop: "50px", marginBottom: "50px"}} className="text-center">
                <ReactGA.OutboundLink
                    eventLabel="survey"
                    to="https://jobpassionassessment.com/jpti-feedback"
                    target="_blank"
                >
                    <img className="d-block w-100" src={jptiCta} alt="Feedback" />
                </ReactGA.OutboundLink>
                </div>

        </Container>
    )
}

const Styles = {
    typeList: {
        paddingLeft: '27px'
    },
    typeListItem: {
        paddingLeft: '6px'
    }
}