import React, { Fragment } from 'react'
import { Container, Col, Row } from 'reactstrap'

import { KartraVideo } from '../../Utils/KartraVideo'

import { brandName } from './vars'

import scaleImg from '../../../assets/scale_99.png'

const videoStyle = {
    width: '100%',
    height: 'auto'
}

export const HomePage = () => {

    return (
    <Fragment>
        <video style={videoStyle} controls autoPlay>
            <source src="https://storage.googleapis.com/msgsndr/JPgTTcr3R3HaG481VtWA/media/646237299fc03509af028ebd.mp4" type="video/mp4" />
        </video>

        <h1 className="mt-5">Welcome to the {brandName} Type Indicator (DJTI)&trade; Assessment</h1>
        
        <p>You are about to enjoy an experience of self-discovery.</p>
        <p>
            This assessment is intended to be a snapshot of who you authentically
            are at this moment in time—the "True You."
        </p>
        <p>
            You'll see a series of random statements on the following pages. For each statement,
            rate how TRUE they are for you in your life right now, based on the following scale
            from Never TRUE to Always TRUE:
        </p>

        <Container fluid={true}>
            <Row style={{marginTop: 10, marginBottom: 10}}>
                <Col style={{padding: 0}} xs="12" sm="10" md="8" lg="6"><img className="d-block w-100" src={scaleImg} alt="Rating Scale" /></Col>
                <Col></Col>
            </Row>
            

            <ul style={{marginTop: 15}}>
                <li>
                    You should be choosing ALWAYS for any statement that is TRUE 99% to
                    100% of the time. Think of ALWAYS as being TRUE for you every single
                    time except for maybe only once or twice a year.
                </li>
                <li>Choose NEVER if a statement is TRUE for you less than 20% of the time.</li>
                <li>The percentage ranges to use for RARELY, DEPENDS, and OFTEN are indicated by the green bars.</li>
                <li>If you think your response depends on who it is you are interacting with, select DEPENDS (not OFTEN or ALWAYS).</li>
            </ul>

        </Container>
        <p>
            There are no right or wrong answers. Your responses simply reveal what's authentically creating
            happiness for you in your life right now.
        </p>
        <div style={{padding: "10px", marginBottom: "20px"}} className="alert-warning">
            <p>TIPS FOR CAPTURING YOUR TRUTHS:</p>
            <ol>
                <li>
                    Make sure your responses are a genuine reflection of who you TRULY are
                    and not what other people like parents, partners, friends, bosses, or
                    coworkers expect you to be. Answer as if there were no fear of being
                    judged by others.
                </li>
                <li>
                    Also, make sure that your responses aren't based on what you admire in other
                    people or what you think you need to change about yourself.
                </li>
                <li>
                    While selecting your responses, imagine you are making notes in your private
                    journal that nobody will ever see except you.
                </li>
            </ol>
        </div>
        <p>
            You'll be able to see your progress towards completion as you move through the assessment.
            Use the back button if you wish to review or change a response. Then use the continue button
            to move forward again.
        </p>
        <p>
            Typically, you'll need between 20 and 30 minutes to complete this assessment. If you need to
            pause partway through, you can come back later and pick up where you left off because we save
            your responses automatically.
        </p>
        <p>
            As soon as you complete the assessment, you'll get instant access to your results in your&nbsp; 
            {brandName} DNA Profile. This report will reveal your {brandName} Values, your {brandName} Types
            and your {brandName} Title suggestions based on your responses. 
        </p>
        <p>
            Let's get started. Have fun!
        </p>
    </Fragment>
    )
}