import React, { useEffect, Fragment } from 'react'

import { Container, Row, Col } from 'reactstrap'

import renderHTML from 'react-render-html';

import { GetBrandedPage, getBrandName } from '../Branding'

const brandName = getBrandName()


export const ResultThemes = ({themeList, onCheckCb}) => {

    const themeCount = themeList.length

    const getThemeCountText = () => {
        switch (themeCount) {
            case 3: return 'three'
            case 2: return 'two'
            case 1: return 'one'
            default: 
                if(themeCount > 3) {
                    return 'three, including ties,'
                }
                return themeCount
        }
    }

    const themeCountText = getThemeCountText()

    const RenderThemeValues = React.memo(({values}) => {
        
        return (
            <Row>
                <Col col="12">
                    <ul>
                        {values.map((item, index) => (<li key={index}>{item}</li>))}
                    </ul>
                </Col>
            </Row>
        )
    })

    const RenderThemeValueList = React.memo(({name, values}) => {
        if(!values || values.length <= 0) {
            return (
                <p>You do not have any {name} Values in this {brandName} Type.</p>
            )
        } else {
            return (
                <Fragment>
                    <p>YOUR {name} Values that align with this {brandName} Type are:</p>
                    <RenderThemeValues values={values} />
                </Fragment>
            )
        }
    })

    const RenderTheme = React.memo(({theme}) => {
        return (
            <Container style={{backgroundColor: "#e3e8f2", color: "#2c3b59", marginTop: "50px", padding: "20px"}}>
                <h1>
                    <input type="checkbox" checked={theme.selected ? 'checked' : ''} onChange={e => {
                        e.preventDefault()
                        onCheckCb(theme.id, 'theme', e.target.checked)}} />{' '}
                        {theme.name}
                    </h1>
                <div>
                    {renderHTML(theme.desc.replace(/_BRAND_NAME_/g, brandName))}
                </div>

                <RenderThemeValueList name="Core" values={theme.core_values} />

                <RenderThemeValueList name="Supporting" values={theme.support_values} />

            </Container>
        )
    })

    const ThemeItems = React.memo(() => themeList.map((item, index) => {
        return (
            <RenderTheme key={`rth${index}`} theme={item} />
        )
    }))

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const BrandedThemesPage = GetBrandedPage({pageName: 'themes', ThemeItems, themeCount, themeCountText})

    return <BrandedThemesPage />
}