import React, { useEffect } from 'react'

import { Container, Table, Input, Row, Col } from 'reactstrap'
import ReactGA from 'react-ga'

import { getBrandName, GetBrandedPage } from '../Branding'

const brandName = getBrandName()
import wbt from '../../assets/DreamJobBookCover400.png'
const brandNameUpper = brandName.toUpperCase()


export const ResultValues = ({coreValues, supportValues, drivers, characterValues, onCheckCb} = props) => {

    const RenderValues = React.memo(({valueType, valueName, valueList, headBg, rowBg1, rowBg2,
        emptyValue='Not applicable', emptyDesc='Your responses did not identify any of these values.'}) => {

        let bodyRows = <tr><td></td><td>{emptyValue}</td><td>{emptyDesc}</td></tr>
        if((valueList || []).length > 0) {
            bodyRows = valueList.map((item, index) => {
                return (
                    <tr key={item.id} style={{backgroundColor: (index % 2) ? rowBg2 : rowBg1}}>
                        <td><input type="checkbox" checked={item.selected ? 'checked' : ''} onChange={e => {
                            e.preventDefault()
                            onCheckCb(item.id, valueType, e.target.checked)}} /></td>
                        <td>{item.name}</td>
                        <td>{item.desc}</td>
                    </tr>
                )
            })
        }

        return (
            <Table striped bordered className="values-table">
                <thead style={{backgroundColor: headBg, color: "#FFF"}}>
                    <tr>
                    <th style={{width: "5%", whiteSpace: "nowrap"}}><input type="checkbox" checked="checked" disabled="disabled" /></th>
                    <th style={{width: "20%", whiteSpace: "nowrap"}}>{valueName} Value</th>
                    <th style={{width: "75%", whiteSpace: "nowrap"}}>Description</th>
                    </tr>
                </thead>
                <tbody className="values-body">
                    {bodyRows}
                </tbody>
            </Table>
        )
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const BrandValuesPage = GetBrandedPage({pageName: 'values', RenderValues, coreValues, supportValues, drivers, characterValues})

    return <BrandValuesPage />
}