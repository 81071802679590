import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga'
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Provider, useSelector, useDispatch } from 'react-redux';
import { Store } from '../../redux/helpers';

import { resultActions, demographicActions } from '../../redux/actions'

import { Header, PrivateRoute, AuthRoute } from './'
import { AuthNavPage, LogoutPage } from '../Auth'
import { AssessmentPage } from '../Assessment'
import { ResultPage, ResultReportPage } from '../Result'
import { DevScreens } from '../DevScreens'
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { getBrandNameFull, GetBrandedPage, getFavicon, getBrandName } from '../Branding'
import { Helmet } from 'react-helmet'

/*Fontawesome config*/
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faCog,
    faSpinner,
    faSignOutAlt,
    faIdCard,
    faFilter,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight
} from '@fortawesome/free-solid-svg-icons'
import { Loading } from "../Utils/Loading";
library.add(
    faCog,faSpinner,faSignOutAlt,faIdCard, faFilter,
    faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight
)

export const App = () => {
    const [siteFavicon, setSiteFavicon] = useState('')
    const [siteTitle, setSiteTitle] = useState('')

    useEffect(() => {
        ReactGA.initialize('UA-167148127-1',{debug: false})

        setSiteFavicon(getFavicon())
        setSiteTitle(getBrandName())
    },[])

    return (
        <Provider store={Store}>
            <BrowserRouter>
                <Helmet>
                    <link rel="icon" type="image/png" sizes="16x16" href={siteFavicon} />
                    <title>{siteTitle}</title>
                </Helmet>
                <AppFrame />
            </BrowserRouter>
        </Provider>
  );
}

const AppFrame = () => {
    useSelector(store => store.auth.data?.domain || null)

    const PrivacyPage = GetBrandedPage({pageName: 'privacy'})
    const TermsPage = GetBrandedPage({pageName: 'terms'})

    return (
        <>
            <Header />
            <Container style={{marginTop: "50px"}}>
                <Switch>
                    <PrivateRoute exact path="/" component={HomePage} />

                    <AuthRoute path="/login:a" component={AuthNavPage} />

                    <AuthRoute path="/login" component={AuthNavPage} />

                    <PrivateRoute path="/logout" component={LogoutPage} />

                    <Route path="/privacy_policy" component={PrivacyPage} />

                    <Route path="/terms_and_conditions" component={TermsPage} />

                    <Route path="/screen_test" component={DevScreens} />

                    <PrivateRoute path="/result/report" component={ResultReportPage} />

                    <Route path="*" component={NoMatchPage} />
                </Switch>
            </Container>
            <AppFooter />
        </>
    )
}

const NoMatchPage = (props) => (
    <h3>404 - {props.location.pathname} not available</h3>
);

const HomePage = (props) => {
    const result = useSelector(store => store.results)
    const { isAssessed, core_values, support_values, drivers, qualifiers, themes, date_created, user, isLoading } = result

    const dispatch = useDispatch();

    //todo add dispatch/selector for additional demo information for name and such here
    const demo = user ? {
        name: `${user.first_name} ${user.last_name}`,
        date: new Date(date_created).toLocaleString('en-US', {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        position: user.position,
        years: user.time_at_position
    } : null

    const resultCheckCb = (id, type, state) => {
        dispatch(resultActions.update([{
            id: id,
            type: type,
            state: state
        }]))
    }

    useEffect(() => {
        dispatch(resultActions.get())
        dispatch(demographicActions.get())
    },[])

    if(isLoading) {
        return <Loading />
    }

    if(isAssessed) {
        return <ResultPage
            coreValues={core_values}
            supportValues={support_values}
            drivers={drivers}
            characterValues={qualifiers}
            themes={themes}
            demo={demo}
            onCheckCb={resultCheckCb} />
    } else {
        return <AssessmentPage isAssessed={isAssessed} />
    }
}

const AppFooter = (props) => {
    const PrivacyPage = GetBrandedPage({pageName: 'privacy'})
    const TermsPage = GetBrandedPage({pageName: 'terms'})

    if(window.location.pathname == '/result/report') {
        return <div/>
    }
    
    return(
        <div style={{marginBottom: "50px"}} className="text-center">
            <hr />
            <Container className="page-footer">
                <Container>
                    <Row>
                        <Col>
                            <Container>
                                <Row className="footerRow">
                                    <Col col="6" className="text-left">
                                        Copyright &copy; {new Date().getFullYear()} {getBrandNameFull()}&trade;<br />
                                        All Rights Reserved
                                    </Col>
                                    <Col col="6" className="text-right">
                                        <AppPagePopup prompt="Privacy Policy" title="Privacy Policy" Body={PrivacyPage} trackingPage="privacy_policy"/> |
                                        <AppPagePopup prompt="Terms &amp; Conditions" title="Terms &amp; Conditions" Body={TermsPage} trackingPage="terms_and_conditions" />                    
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

const AppPagePopup = ({prompt, title, Body, trackingPage}) => {

    const [modal, setModal] = useState(false)

    const toggle = () => {

        if(!modal && trackingPage) {
            //going to display
            ReactGA.pageview(trackingPage)
        }

        setModal(!modal)
    }

    return (
        <div className="d-inline">
            <a onClick={toggle} style={{cursor: "pointer"}}>{prompt}</a>
            <Modal isOpen={modal} toggle={toggle} modalClassName="modal-fullscreen">
                <ModalHeader>{title}</ModalHeader>
                <ModalBody><Body /></ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
