import { userConstants as constants, authConstants } from '../constants';
import { userServices as services } from '../services';
//import { alertActions } from './';
//import { history } from '../helpers';

export const userActions = {
    register,
    passwordReset
};

function register(email) {
    return dispatch => {
        dispatch(request(true));

        services.register(email)
            .then(
                session => {
                    dispatch(successLogin(session))
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: constants.REGISTER_REQUEST } }
    function success(data) { return { type: constants.REGISTER_SUCCESS, data } }
    function successLogin(data) { return {type: authConstants.LOGIN_SUCCESS, data}}
    function failure(error) { return { type: constants.REGISTER_FAILURE, error } }
}

function passwordReset(email) {
    return dispatch => {
        dispatch(request(true));

        services.passwordReset(email)
            .then(
                () => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: constants.PASSWORD_REQUEST } }
    function success(data) { return { type: constants.PASSWORD_SUCCESS, data } }
    function failure(error) { return { type: constants.PASSWORD_FAILURE, error } }
}