import React, { Fragment } from 'react'
import { Card, CardBody, Button } from 'reactstrap'

import ReactGA from 'react-ga'

export const SignupPage = () => {

    return (
    <Card>
        <CardBody><CardBody>
            <div></div>
            <p>
              Sign up to take the DJTI Assessment&trade; and discover the job that fuels your passions and purpose.
            </p>
            <p>
                The DJTI&trade; is the only assessment available that's capable of identifying the kind of work
                that you can feel deeply passionate about doing.
            </p>
            <div className="text-center" style={{marginTop: "20px"}}>
                <ReactGA.OutboundLink
                    eventLabel="sign_up"
                    to="https://www.dreamjobdiscovery.com/djti-career-assessment"
                    target="_blank"
                ><Button color="primary">LEARN MORE</Button></ReactGA.OutboundLink>
            </div>
        </CardBody></CardBody>
    </Card>
    )
}