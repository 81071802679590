import React, { Fragment } from 'react'

import { Container, Row, Col } from 'reactstrap'

import ReactGA from 'react-ga'

import wbt from '../../../assets/DreamJobBookCover400.png'


export const ThemesPage = ({ThemeItems, themeCount, themeCountText}) => {

    return (
        <Container>
            <h1>Your DreamJob Types&trade;</h1>
            <p>
            When you think about it, activities you feel deeply passionate about doing are ones that fill you
            with a sense of joy, meaning, and fulfillment. This is how you should expect to feel when you have
             a job that's in direct alignment with your values. That's why we use your values to identify the
             type of work - your DreamJob Type - that would be a dream for you to do.
            </p>
            {
                (themeCount > 1) &&
                <p>
                    There are 21 DreamJob Types. We've identified your top-three DreamJob Types based on how
                    directly they align with your Core values and Supporting values.
                </p>
            }
            {
                (themeCount == 1) &&
                <p>
                    There are 21 DreamJob Types. We've identified your top DreamJob Type based on how
                    directly they align with your Core Values and Supporting Values.
                </p>
            }
            {
                (themeCount < 1) &&
                <div />
            }
            <p>
                Just like a book or movie genre categorizes different stories into classifications based on a
                common type like Mystery or Romance, DreamJob Types categorize job roles based on a common
                type of workflow process such as Persuading or Designing.
            </p>
            <p>
                And, just like you'll typically enjoy books and movies that fit particular genres, you'll tend
                to enjoy a variety of job roles that align well with your DreamJob Types.
            </p>
            {
                (themeCount > 1) &&
                <Fragment>
                    <p>
                        Here are YOUR top three DreamJob Types (including ties for first, second, or third ranking)
                        based on your responses to the assessment.
                    </p>
                </Fragment>
            }
            {
                (themeCount == 1) &&
                <Fragment>
                    <p>
                        Here is YOUR top DreamJob Type based on your responses to the assessment.
                    </p>
                </Fragment>
            }
            {
                (themeCount < 1) &&
                <div />
            }

            <ThemeItems />

            <h2>What To Do Next</h2>


            <div className="alert-warning p-4 my-3">
                <Row className="align-items-center">
                    <Col xs="12" sm="4" md="2">
                        <ReactGA.OutboundLink
                            eventLabel="download_workbook_image"
                            to="https://www.amazon.com/Dream-Job-Discovery-Passion-Inspires-ebook/dp/B09FB1HT6X/ref=sr_1_1?dchild=1&amp;keywords=Dream+Job+Discovery&amp;qid=1631055764&amp;s=books&amp;sr=1-1"
                            target="_blank"
                        ><img className="d-block mw-100" src={wbt} alt={`Dream Job Discovery`} /></ReactGA.OutboundLink>
                    </Col>
                    <Col>
                        <p className="mt-4">
                            Be sure to read Chapter 5 - What's Your Type? - in Dream Job Discovery for a detailed description of all 21
                            DreamJob Types, so that you can see the realm of possibility and determine whether or not the three
                            identified by the DJTI assessment are a good fit for you.
                        </p>
                    </Col>
                </Row>
            </div>

            {
                (themeCount > 0) &&
                <Fragment>
                    <p>
                        If you were truthful in answering the assessment so that it reflects your authentic self,
                        then one of your top-three is likely your dominant DreamJob Type. Choose which of the
                        three resonates the most with you. 
                    </p>
                    <p>
                        Once you've identified which DreamJob Type you believe is the best fit for you, it's time
                        to discover what your DreamJob Titles could be. These are specific job roles that would
                        require you to use the job passion workflow process associated with your DreamJob Type.
                    </p>
                    <p>
                        Click the button below to reveal your DreamJob Titles.
                    </p>
                </Fragment>
            }
            {
                (themeCount < 1) &&
                <div>
                    Your combination of Core and Supporting values did not clearly define any DreamJob Types.
                    Please contact <a href="mailto:support@dreamjobdiscovery.com" target="_blank">support@dreamjobdiscovery.com</a>
                    {' '} for assistance.
                </div>
            }

        </Container>
    )
}