import Logo from './logo.svg'
import PrintLogo from './print-logo.svg'
import CoverImage from './report_cover.jpg'
import { HomePage } from './HomePage'
import { SignupPage } from './SignupPage'
import { PrivacyPage } from './PrivacyPage'
import { TermsPage } from './TermsPage'
import { ValuesPage } from './ValuesPage'
import { ThemesPage } from './ThemesPage'
import { TitlesPage } from './TitlesPage'
import { brandName, brandNameFull } from './vars'
import Favicon from './favicon.ico'

export default {
    favicon: Favicon,
    brandName,
    brandNameFull,
    displayDemographics: true,
    Logo: Logo,
    PrintLogo,
    CoverImage,
    HomePage,
    SignupPage,
    PrivacyPage,
    TermsPage,
    ValuesPage,
    ThemesPage,
    TitlesPage
}