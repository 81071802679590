export const authConstants = {
    LOGIN_REQUEST:      'LOGIN_REQUEST',
    LOGIN_SUCCESS:      'LOGIN_SUCCESS',
    LOGIN_FAILURE:      'LOGIN_FAILURE',

    LOGOUT_REQUEST:     'LOGOUT_REQUEST',
    LOGOUT_SUCCESS:     'LOGOUT_SUCCESS',
    LOGOUT_FAILURE:     'LOGOUT_FAILURE',

    AUTH_EXPIRED:       'AUTH_EXPIRED',
}