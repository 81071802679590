import React from 'react';

import { Spinner } from 'reactstrap'

export const Loading = () => {
    return(
        <div className="col-12">
            <Spinner color="primary" style={{ width: '4rem', height: '4rem' }} type="grow" />
        </div>
    );
};