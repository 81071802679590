import ReactGA from 'react-ga'
import moment from 'moment';

export const reducerHelpers = {
    initRequestTime,
    sendGAEvent,
    sendGATiming,
    processSuccessEvent,
    processFailureEvent
};

function initRequestTime() {
    return moment();
}

function  sendGAEvent(category, action, value=null) {
    let args = {
        category: category,
        action: action
    }
    if(value && Number.isInteger(value)) {
        args['value'] = value
    }
    ReactGA.event(args)
}

function sendGATiming(category, name, lastRequest) {
    ReactGA.timing({
        category: category,
        variable: name,
        value: moment().diff(lastRequest)
    })
}

function sendGAError(error){
    ReactGA.exception({
        description: error.message || 'Unkown error 0x12345AA',
        fatal: (error.code != 'invalid') || true
    })
}

function processSuccessEvent(category, lastRequest, options={}) {
    const {eventVal=null, action='Successful', timeVar='load success'} = options
    sendGAEvent(category, action, action, eventVal)
    sendGATiming(category, timeVar, lastRequest)
}

function processFailureEvent(category, lastRequest, error, options={}) {
    const {action='Failure', timeVar='load failed'} = options
    sendGAEvent(category, action)
    sendGATiming(category, timeVar, lastRequest)
    sendGAError(error)
}