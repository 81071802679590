import { serviceHelpers } from '../helpers';

export const demographicServices = {
    get,
    save
};

function get() {

    const requestOptions = {
        method: 'GET',
        headers: serviceHelpers.getServiceHeaders(true)
    };

    const url = serviceHelpers.getServiceUrl('/user/demo');    

    return fetch(url, requestOptions)
    .then(serviceHelpers.handleServiceResponse)
    .catch(serviceHelpers.handleFetchError)
}

function save(data) {

    const requestOptions = {
        method: 'PATCH',
        headers: serviceHelpers.getServiceHeaders(true),
        body: JSON.stringify(data)
    };

    const url = serviceHelpers.getServiceUrl('/user/demo');    

    return fetch(url, requestOptions)
    .then(serviceHelpers.handleServiceResponse)
    .catch(serviceHelpers.handleFetchError)
}