import { assessmentConstants as constants } from '../constants';
import { reducerHelpers } from '../helpers';

export function assessments(state = {
    isLoading: false,
    questions: null,
    error: null,
    lastRequest: null
}, action) {
  switch (action.type) {

    case constants.PAGE_REQUEST:
        return {...state,
            isLoading: true,
            lastRequest: reducerHelpers.initRequestTime()
        };

    case constants.PAGE_SUCCESS:

        reducerHelpers.processSuccessEvent('Assessment', state.lastRequest, {eventVal: action.data.page_current || 0})

        return {...state,
            isLoading: false,
            questions: action.data,
            error: null
        };

    case constants.PAGE_FAILURE:

        reducerHelpers.processFailureEvent('Assessment', state.lastRequest, action.error)

        return {...state,
            isLoading: false,
            error: action.error
        };
    
    default:
      return state
  }
}