import { demographicConstants as constants } from '../constants';
import { reducerHelpers } from '../helpers';

export function demographics(state = {
    isLoading: false,
    isProccessed: false,
    data: null,
    error: null,
    lastRequest: null
}, action) {
    switch (action.type) {

        case constants.REQUEST:
            return {...state,
                isLoading: true,
                lastRequest: reducerHelpers.initRequestTime()
            };

        case constants.SUCCESS:

            reducerHelpers.processSuccessEvent('Demographic', state.lastRequest)

            return {...state,
                isLoading: false,
                isProccessed: (action.data && action.data.career_status) ? true : false,
                data: action.data,
                error: null
            };

        case constants.FAILURE:

            reducerHelpers.processFailureEvent('Demographic', state.lastRequest, action.error)

            return {...state,
                isLoading: false,
                error: action.error
            };
        
        default:
            return state
            
    }
}