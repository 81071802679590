import React, { useState, useEffect, Fragment } from 'react'

import { Container, Row, Col, Popover, PopoverHeader, PopoverBody } from 'reactstrap'

import { GetBrandedPage, getBrandName } from '../Branding'

import ReactGA from 'react-ga'

const brandName = getBrandName()


export const ResultTitles = ({themeList, onCheckCb}) => {

    const sortJobs = (a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if(nameA > nameB) {
            return 1
        } else if(nameA < nameB) {
            return -1
        } else {
            return 0
        }
    }

    const splitTwo = list => {
        let i=0;
        return list.sort(sortJobs).reduce((result, item) => {
            const index = (i++ % 2)
            result[index].push(item)
            return result
        },{0:[], 1:[]})
    }

    const groupJobs = jobs => jobs.reduce((result, item) => {
        if(!result[item.group_name]) {
            result[item.group_name] = []
        }
        result[item.group_name].push(item)
        return result
    },{})

    const JobGroupTitle = ({title}) => {
        if(!title) {
            return <div></div>
        } else {
            return <h3>{title}</h3>
        }
    }

    const RenderThemeJobs = ({themeId, jobs, onCheckCb}) => {
        
        const jobGroups = groupJobs(jobs)
        
        return (
            <div>
                {
                    Object.keys(jobGroups).map((group, index) => {
                        return (
                            <div key={`tj_${themeId}${index}`}>
                                <JobGroupTitle title={group} />
                                <RenderThemeJobItems themeId={themeId} jobs={jobGroups[group]} onCheckCb={onCheckCb} />
                                {
                                    group && <hr />
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const RenderThemeJobItems = ({themeId, jobs, onCheckCb}) => {

        const list = splitTwo(jobs)
        
        return (
            <Row>
                <Col xs="12" md="6">
                    <ul>
                        {list[0].map((item) => (<JobItem key={`tji_${themeId}${item.id}`} themeId={themeId} job={item} onCheckCb={onCheckCb} />))}
                    </ul>
                </Col>
                <Col xs="12" md="6">
                    <ul>
                        {list[1].map((item) => (<JobItem key={`tji_${themeId}${item.id}`} themeId={themeId} job={item} onCheckCb={onCheckCb} />))}
                    </ul>
                </Col>
            </Row>
        )
    }

    const RenderTheme = ({theme, onCheckCb}) => {
        return (
            <Container style={{backgroundColor: "#e3e8f2", color: "#2c3b59", marginTop: "50px", padding: "20px"}}>
                <h1>{theme.name}</h1>

                <p><b>
                    Based on your responses, here are the MOST RELEVANT job title suggestions for you:
                </b></p>                
                <RenderThemeJobs themeId={theme.id} jobs={theme.jobs.filter(i => !i.filtered)} onCheckCb={onCheckCb} />

                {
                    theme.jobs.filter(i => i.filtered).length > 0 &&
                    <Fragment>
                        <Container style={{backgroundColor: "#F5F5F5"}}>         
                            <p color="#F00">
                                Here are the REMAINING job title suggestions that also apply to this {brandName} Type:
                            </p>
                            <RenderThemeJobs themeId={theme.id} jobs={theme.jobs.filter(i => i.filtered)} onCheckCb={onCheckCb} />
                        </Container>
                    </Fragment>
                }

            </Container>
        )
    }

    const ThemeItems = () => themeList.map((item, index) => {
        return (
            <RenderTheme key={index} theme={item} onCheckCb={onCheckCb} />
        )
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const BrandedTitlesPage = GetBrandedPage({pageName: 'titles', ThemeItems, themeListCount: themeList.length})

    return <BrandedTitlesPage />
}

const JobItem = ({themeId, job, onCheckCb}) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
  
    const toggle = () => {
        if(!popoverOpen) {
            ReactGA.event({
                category: 'SOC Job Description',
                action: job.soc_code
            })
        }
        setPopoverOpen(!popoverOpen);
    }

    const itemIdStr = `job_item_${themeId}${job.group_name}${job.id}`.replace(/[^A-Za-z0-9]/g, '')
    
    return (
      <div className="job-item-group">
        <li id={itemIdStr}>
            <input type="checkbox" checked={job.selected ? 'checked' : ''} onChange={e => {
                e.preventDefault()
                onCheckCb(job.id, 'job', e.target.checked)}} />{' '}
            {job.name}
        </li>
        <Popover trigger="legacy" isOpen={popoverOpen} placement="bottom-start" target={itemIdStr} toggle={toggle}>
            <PopoverHeader>{job.name}</PopoverHeader>
            <PopoverBody>{job.desc}</PopoverBody>
        </Popover>
      </div>
    );
  }