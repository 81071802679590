import { serviceHelpers } from '../helpers';

export const resultServices = {
    create,
    update,
    get
};

function create() {

    const requestOptions = {
        method: 'POST',
        headers: serviceHelpers.getServiceHeaders(true)
    };

    const url = serviceHelpers.getServiceUrl('/result');    

    return fetch(url, requestOptions)
    .then(serviceHelpers.handleServiceResponse)
    .catch(serviceHelpers.handleFetchError)
}

function update(items) {

    const requestOptions = {
        method: 'PUT',
        headers: serviceHelpers.getServiceHeaders(true),
        body: JSON.stringify(items)
    };

    let options = {}

    const url = serviceHelpers.getServiceUrl('/result', options);

    return fetch(url, requestOptions)
    .then(serviceHelpers.handleServiceResponse)
    .catch(serviceHelpers.handleFetchError)
}

function get() {

    const requestOptions = {
        method: 'GET',
        headers: serviceHelpers.getServiceHeaders(true)
    };

    let options = {}

    const url = serviceHelpers.getServiceUrl('/result', options);

    return fetch(url, requestOptions)
    .then(serviceHelpers.handleServiceResponse)
    .catch(serviceHelpers.handleFetchError)
}