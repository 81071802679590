import { authConstants as constants } from '../constants';
import { authServices as services } from '../services';
//import { alertActions } from './';
//import { history } from '../helpers';

export const authActions = {
    login,
    logout,
    getAll
};

function login(username, password) {
    return dispatch => {
        dispatch(request(true));

        services.login(username, password)
            .then(
                session => { 
                    dispatch(success(session));
                    //history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: constants.LOGIN_REQUEST } }
    function success(data) { return { type: constants.LOGIN_SUCCESS, data } }
    function failure(error) { return { type: constants.LOGIN_FAILURE, error } }
}

function logout() {

    return dispatch => {
        dispatch(request(true));

        services.logout()
            .then(
                _data => { 
                    dispatch(success());
                    //history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: constants.LOGOUT_REQUEST } }
    function success() { return { type: constants.LOGOUT_SUCCESS } }
    function failure(error) { return { type: constants.LOGOUT_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        services.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: constants.GETALL_REQUEST } }
    function success(users) { return { type: constants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: constants.GETALL_FAILURE, error } }
}