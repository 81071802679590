import React, { useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'

import { Loading } from '../Utils/Loading'

import { authActions } from '../../redux/actions'

export const LogoutPage = (props) => {

    const [isRequested, setIsRequested] = useState(false)

    const {error, isAuthenticated} = useSelector(store => store.auth)
    
    const dispatch = useDispatch();

    if(!isRequested) {
        setIsRequested(true);
        dispatch(authActions.logout());
    }

    const ErrorDiv = () => { 
        if(error) {
            return <div className="alert alert-danger" role="alert">{error.message || 'Unknown error has occured'}</div>
        } else {
            return <div />;
        }
    }

    if(!isAuthenticated) {
        return <Route><Redirect to='/login' /></Route>
    }

    return (
        <Container>
            <Row>
                <Col xs="12" sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
                    <Card className="align-self-center">
                        <CardHeader>
                            <h4>Loggin out...</h4>
                        </CardHeader>
                        <CardBody>
                            <Loading />
                        </CardBody>
                    </Card>
                </Col>
            </Row>            
        </Container>
    )
    
}