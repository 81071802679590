import { resultConstants as constants } from '../constants';
import { resultServices as services } from '../services';

export const resultActions = {
    create,
    update,
    get
};

function create() {
    return dispatch => {
        dispatch(request());

        services.create()
            .then(
                (data) => {                    
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: constants.RESULT_REQUEST } }
    function success(data) { return { type: constants.RESULT_SUCCESS, data } }
    function failure(error) { return { type: constants.RESULT_FAILURE, error } }
}

function update(items) {
    return dispatch => {
        dispatch(request());

        services.update(items)
            .then(
                (data) => {                    
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: constants.RESULT_REQUEST } }
    function success(data) { return { type: constants.RESULT_SUCCESS, data } }
    function failure(error) { return { type: constants.RESULT_FAILURE, error } }
}

function get() {
    return dispatch => {
        dispatch(request());

        services.get()
            .then(
                (data) => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: constants.RESULT_REQUEST } }
    function success(data) { return { type: constants.RESULT_SUCCESS, data } }
    function failure(error) { return { type: constants.RESULT_FAILURE, error } }
}