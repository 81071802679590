import React from 'react';

export const TermsPage = () => {
    
    return (
        <>
            <h1>Terms and Conditions</h1>
            <p>Last updated: December 3, 2022</p>
            <p>
                These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship
                with https://www.jobpassionassessment.com website (the "Site") operated by
                Job Passion Assessment ("us", "we", or "our").
            </p>
            <p>
                Please read these Terms and Conditions carefully before using the Site.
                Your access to and use of the Site is based on your acceptance of and compliance with
                these Terms. These Terms apply to all visitors, users and others who access or use the
                Site.
            </p>
            <p>
                By accessing or using the Site you agree to be bound by these Terms and accept all legal
                consequences. If you do not agree to these terms and conditions, in whole or in part, please
                do not use the Site.
            </p>

            <h2>Purchases</h2>
            <p>
                If you wish to purchase any product or service made available through the Site
                ("Purchase"), you may be asked to supply certain information relevant to your Purchase
                including, without limitation, your credit card number, the expiration date of your credit card,
                your billing address, and your shipping information.
            </p>
            <p>
                You represent and warrant that: (i) you have the legal right to use any credit card(s) or other
                payment method(s) in connection with any Purchase; and that (ii) the information you
                supply to us is true, correct and complete.
            </p>
            <p>
                By submitting such information, you grant us the right to provide the information to third
                parties for purposes of facilitating the completion of Purchases.
            </p>
            <p>
                We reserve the right to refuse or cancel your order at any time for certain reasons including,
                but not limited to, product or service availability, errors in the description or price of the
                product or service, error in your order or other reasons.
            </p>
            <p>
                We reserve the right to refuse or cancel your order if fraud or an unauthorised or illegal
                transaction is suspected. We will not be held responsible or liable for any failure for the
                Purchase to complete, or any resulting loss or damages to you.
            </p>

            <h2>Availability, Errors and Inaccuracies</h2>
            <p>
                In order to provide exceptional service, and accuracy, we regularly update the products and
                services on the Site.
            </p>
            <p>
                We cannot and do not guarantee the accuracy or completeness of any information,
                including prices, product images, specifications, availability, and services. We reserve the
                right to change or update information and to correct errors, inaccuracies, or omissions at
                any time without prior notice.
            </p>
            <p>
                Despite our best efforts, the products or services available on our Site may have an error
                regarding the price, be inaccurately described, or be unavailable.
            </p>
            <p>
                We may experience delays in updating information on the Site and in our advertising on
                other websites.
            </p>

            <h2>Accounts</h2>
            <p>
                When you create an account with us, you must provide us information that is accurate,
                complete, and current at all times. Failure to do so constitutes a breach of the Terms, which
                may result in immediate termination of your account on our Site.
            </p>
            <p>
                You are responsible for safeguarding the password that you use to access your Purchases
                on the Site and for any activities or actions under your password, whether your password is
                with our Site or a third-party service.
            </p>
            <p>
                You agree not to disclose your password to any third party. You must notify us immediately
                upon becoming aware of any breach of security or unauthorized use of your account.
            </p>
            <p>
                You may not use as a username the name of another person or entity or that is not lawfully
                available for use, a name or trade mark that is subject to any rights of another person or
                entity other than you without appropriate authorization, or a name that is otherwise
                offensive, vulgar or obscene.
            </p>

            <h2>Intellectual Property</h2>
            <p>
                The Site and all contents, including but not limited to text, images, graphics or code are the
                property of Job Passion Assessment and are protected by copyright, trademarks, database and
                other intellectual property rights. You may display and copy, download or print portions of
                the material from the different areas of the Site only for your own non-commercial use, or to
                place an order with Job Passion Assessment. Any other use is strictly prohibited and may
                violate copyright, trademark and other laws. These Terms do not grant you a license to use
                any trademark of Job Passion Assessment or its affiliates. You further agree not to use, change
                or delete any proprietary notices from materials downloaded from the Site.
            </p>

            <h2>Links To Other Web Sites</h2>
            <p>
                The Site may contain links to third-party web sites or services that are not owned or
                controlled by Job Passion Assessment.
            </p>
            <p>
                Job Passion Assessment has no control over, and assumes no responsibility for, the content,
                privacy policies, or practices of any third-party web sites or services. You further
                acknowledge and agree that Job Passion Assessment shall not be responsible or liable, directly
                or indirectly, for any damage or loss caused or alleged to be caused by or in connection
                with use of or reliance on any such content, goods or services available on or through any
                such websites or services.
            </p>
            <p>
                We strongly advise you to read the terms and conditions and privacy policies of any third-
                party web sites or services that you visit.
            </p>

            <h2>Termination</h2>
            <p>
                We may terminate or suspend access to our Site products or services immediately, without
                prior notice or liability, for any reason whatsoever, including, without limitation, if you breach
                the Terms.
            </p>
            <p>
                All provisions of the Terms shall survive termination, including, without limitation, ownership
                provisions, warranty disclaimers, indemnity and limitations of liability.
            </p>
            <p>
                Upon termination, your right to use the products or services of the Site will immediately
                cease. If you wish to terminate your account, you may do so by submitting a request to do
                so via Email.
            </p>

            <h2>Indemnification</h2>
            <p>
                You agree to indemnify, defend and hold harmless Job Passion Assessment, its owners,
                principals, officers, directors, representatives, employees, contractors, licensors, licensees,
                suppliers and agents, from and against any claims, losses, damages, losses, obligations,
                costs, actions or demands.
            </p>
            <p>
                These include but are not limited to: (a) legal and accounting fees resulting from your use of
                the Site; (b) your breach of any of these Terms; (c) anything you post on or upload to the
                Site; and (d) any activity related to your account. This includes any negligent or illegal
                conduct by you, any person or entity accessing the Site using your account whether such
                access is obtained via fraudulent or illegal means.
            </p>

            <h2>Limitation Of Liability</h2>
            <p>
                Job Passion Assessment, its owners, directors, employees, partners, agents, suppliers, or
                affiliates, shall not be liable for any loss or damage, direct or indirect, incidental, special,
                consequential or punitive damages, including without limitation, economic loss, loss or
                damage to electronic media or data, goodwill, or other intangible losses, resulting from (i)
                your access to or use of the Site; (ii) your inability to access or use the Site; (iii) any conduct
                or content of any third-party on or related to the Site; (iv) any content obtained from or
                through the Site; and (v) the unauthorized access to, use of or alteration of your
                transmissions or content, whether based on warranty, contract, tort (including negligence)
                or any other claim in law, whether or not we have been informed of the possibility of such
                damage, and even if a remedy set forth herein is found to have failed of its essential
                purpose.
            </p>

            <h2>Disclaimer And Non-Waiver of Rights</h2>
            <p>
                Job Passion Assessment makes no guarantees, representations or warranties of any kind as
                regards the Site and associated technology. Any purportedly applicable warranties, terms
                and conditions are excluded, to the fullest extent permitted by law. Your use of the Site is at
                your sole risk. The Site is provided on an "AS IS" and "AS AVAILABLE" basis. The Site is
                provided without warranties of any kind, whether express or implied, including, but not
                limited to, implied warranties of merchantability, employability, fitness for a particular
                purpose, non-infringement or course of performance, except as provided for under the laws
                of any province in Canada. In such cases, the provincial law shall apply to the extent
                necessary.
            </p>
            <p>
                Job Passion Assessment, its subsidiaries, affiliates, and its licensors do not warrant that a) the
                Site will function uninterrupted, secure or available at any particular time or location; b) any
                errors or defects will be corrected; c) the Site is free of viruses or other harmful
                components; or d) the results of using the Site will meet your requirements.
            </p>
            <p>
                If you breach any of these Terms and Job Passion Assessment chooses not to immediately act,
                or chooses not to act at all, Job Passion Assessment will still be entitled to all rights and
                remedies at any later date, or in any other situation, where you breach these Terms.
                Job Passion Assessment does not waive any of its rights. Job Passion Assessment shall not be
                responsible for any purported breach of these Terms caused by circumstances beyond its
                control. A person who is not a party to these Terms shall have no rights of enforcement.
                You may not assign, sub-license or otherwise transfer any of your rights under these Terms.
            </p>

            <h2>Exclusions</h2>
            <p>
                As set out, above, some jurisdictions do not allow the exclusion of certain warranties or the
                exclusion or limitation of liability for consequential or incidental damages, so the limitations
                above may not apply to you. Provincial laws of Canada may apply to certain products and
                service provided.
            </p>

            <h2>Governing Law</h2>
            <p>
                These Terms shall be governed by, and interpreted and enforced in accordance with, the
                laws in the Province of Ontario and the laws of Canada, as applicable.
            </p>
            <p>
                If any provision of these Terms is held to be invalid or unenforceable by a court of
                competent jurisdiction, then any remaining provisions of these Terms will remain in effect.
                These Terms constitute the entire agreement between us regarding our Site, and
                supersede and replace any prior agreements, oral or otherwise, regarding the Site.
            </p>
            <h2>Changes</h2>
            <p>
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If
                a revision is material, we will make reasonable efforts to provide at least 30 days’ notice
                prior to any new terms taking effect. What constitutes a material change will be determined
                at our sole discretion.
            </p>
            <p>
                By continuing to access or use our Site after those revisions become effective, you agree to
                be bound by the revised terms. If you do not agree to the new terms, in whole or in part,
                please stop using the Site.
            </p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us.</p>
            <p>
                By Mail:<br />
                Job Passion Assessment<br />
                A Division of KASCORP Holdings Ltd.<br />
                15 Allstate Parkway, Suite 600<br />
                Markham, ON L3R 5B4
            </p>
            <p>
                By Email:<br />
                <a href="mailto:support@jobpassionassessment.com" target="_blank">support@jobpassionassessment.com</a>
            </p>
        </>
    )
}