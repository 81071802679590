import config from 'config';
import { serviceHelpers } from '../helpers';

export const authServices = {
    login,
    logout,
    getAll
};

function login(username, password) {
    return fetch(serviceHelpers.getServiceUrl('/auth'), {
        method: 'POST',
        headers: serviceHelpers.getServiceHeaders(false),
        body: JSON.stringify({ username, password })
        })
        .then(serviceHelpers.handleServiceResponse)
        .then(data => {
            if(!data.scope){//} || data.scope != 'admin') {
                return Promise.reject('Invalid login scope');
            }

            return data;
        })
        .catch(serviceHelpers.handleFetchError)
}

function logout() {
    return fetch(serviceHelpers.getServiceUrl('/auth'), {
        method: 'DELETE',
        headers: serviceHelpers.getServiceHeaders(true)
        })
        .then(serviceHelpers.handleServiceResponse)
        .then(data => data)
        .catch(serviceHelpers.handleFetchError)
}

function getAll(options = {}) {

    return fetch(serviceHelpers.getServiceUrl('/auth', options), requestOptions = {
        method: 'GET',
        headers: serviceHelpers.getServiceHeaders(true)
    })
    .then(handleServiceResponse)
    .catch(serviceHelpers.handleFetchError)
}