import { combineReducers } from 'redux';

import { auth } from './auth.reducer';
import { users } from './user.reducer';
import { assessments } from './assessment.reducer';
import { results } from './result.reducer'
import { demographics } from './demographic.reducer'

const rootReducer = combineReducers({
  auth,
  users,
  assessments,
  results,
  demographics
});

export default rootReducer;
