import React from 'react'

import { Button } from 'reactstrap'

import { getBrandName } from '../Branding'

import { ResultReport } from '../Result'

const brandName = getBrandName().toUpperCase()


export const ResultReportTest = (props) => {
    return <ResultReport {...props} />
    return (
        <div>
            <a href='/result/report' target="_blank"><Button>Open Report</Button></a>
        </div>
    )
}