import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { schemaProps, FormHandler } from "../Utils/FormHandler";

import { Row, Col, Container, Card, CardHeader, CardBody, Form, FormGroup, FormFeedback, Label, Input, Button } from 'reactstrap'

import { Loading } from '../Utils/Loading'

import { userActions } from '../../redux/actions'

export const PasswdResetPage = (props) => {

    const { isLoading, isSuccess, error } = useSelector(store => store.users)
    const dispatch = useDispatch();

    const formSchema = {
        email: {
            value: '',
            [schemaProps.VALIDATOR]: {
                required: true,
            }
        }
    }

   function handleForm({ email }) {
       dispatch(userActions.passwordReset(email))
   }
   const {
       fieldsValue, fieldsError, isDisabled, fieldsValid, fieldsInvalid,
       handleOnChange, handleOnSubmit, handleServiceErrors
    } = FormHandler(formSchema, handleForm)

   const { email } = fieldsValue;

   if(error) {
       handleServiceErrors(error.invalid || [])
   }

   const ErrorDiv = () => { 
       if(error) {
           return <div className="alert alert-danger" role="alert">{error.message || 'Unknown error has occured'}</div>
       } else {
           return <div />;
       }
   }


    if(isLoading) {
        return <Loading />
    } else if(isSuccess) {
        return (
            <Container>
                <Card className="align-self-center">
                    <CardBody>
                        <p>Your password reset information has been sent to your email address.</p>
                        <p>
                            IMPORTANT: It may take up to <b>10-minutes</b> for the email to arrive in your in box.
                            Please be patient and refrain from making a second password request because doing
                            so will invalidate the password we just sent you.
                        </p>
                        <p>
                            If you continue to experience problems logging in, please contact{' '} 
                            <a href="mailto:support@dreamjobdiscovery.com">support@dreamjobdiscovery.com</a> for assistance.
                        </p>
                        <p>Thank you!</p>            
                    </CardBody>
                </Card>
            </Container>
        )
    } else {
        return (
            <Card body>
                <CardBody>
                    <ErrorDiv />
                    <Form onSubmit={handleOnSubmit}>
                        <FormGroup>
                            <Label htmlFor="username">Email</Label>
                            <Input type="text"
                                valid={fieldsValid.email}
                                invalid={fieldsInvalid.email}
                                placeholder="Email"
                                name="email"
                                onChange={handleOnChange}
                                value={email}
                                required />
                                <FormFeedback>{fieldsError.email}</FormFeedback>
                        </FormGroup>
                        <Button type="submit" value="submit" color="primary" disabled={isDisabled}>Reset Password</Button>
                    </Form>
                </CardBody>
            </Card>
        )
    }
    
}