import React from "react"
import ReactGA from 'react-ga'
import wbt from '../../../assets/DreamJobBookCover400.png'
import { Container, Row, Col } from 'reactstrap'

export const ValuesPage = ({RenderValues, coreValues, supportValues, drivers, characterValues}) => {
    return (
        <Container>
            <h1>Your DreamJob Values&trade;</h1>
            <p>Congratulations on identifying your personal values.</p>
            <p>
                You've successfully narrowed down a list of 118 value statements to identify only those
                that define the “True YOU” at this point in your life.
            </p>
            <p>
                Your values are things that you strongly believe define you as a person. You could think
                of them as being your moral and ethical compass. They're the guiding principles you
                subconsciously use to think things through and decide how to interact with people and what
                actions to take in your life.
            </p>
            <p>
                Knowing what values matter most to you is a significant accomplishment that will provide
                insight and guidance as you narrow your career choices to find work that will provide lasting
                happiness. That's because if you do anything for very long that conflicts with your values,
                you'll feel stress, anxiety, or misery. But by doing things that are in alignment with your
                values, you'll feel joy, meaning, and fulfillment instead.
            </p>
            <p>
                We've divided the values that matter most to you into four categories. Each category of
                values has a different role to play in helping you discover your DreamJob.
            </p>

            <h2>Your Core Values</h2>
            <p>
                Core values are the ones that are ALWAYS important in guiding the way you make decisions
                and interact with the world every day. Here are YOUR core values based on your responses
                to the assessment.
            </p>

            <RenderValues valueType="core" valueName="Core" valueList={coreValues} headBg="#3f6caf" rowBg1="#c5cee2" rowBg2="#e3e8f2"
                emptyDesc="Your responses did not reveal any core values for you. This result is no cause for concern. It merely indicates there are no values that you genuinely feel are important enough at this time in your life to be a priority in guiding the way you think, make decisions or act 100% of the time. Instead, you have supporting values that very often, but not always, help guide your life. So, you should treat your Supporting Values as Core Values for the purposes of finding work you love to do."
            />
            
            <p>
                Because you ranked these values as 5 out of 5, your Core values are the most significant
                in defining who you truly are. They are also the most important in predicting which job
                passion workflow process will be your DreamJob Type. You'll discover more about that in
                the next section of this report.
            </p>
            <p>
                Later, when seeking employment, you'll want to refer back to this list to make sure the
                values of the company you choose to work for do not conflict with YOUR Core values.
            </p>
            <p>
                Companies that understand the importance of Core values typically hire only people whose
                Core values fit their own. In doing so, they create a corporate culture that enlivens
                their like-minded employees.
            </p>

            <h2>Your Supporting Values</h2>
            <p>
                As the name suggests, Supporting values play a key “supporting role” in defining who you
                truly are. They apply at least 80% of the time, but not virtually 100% of the time like
                Core values do. They are the ones you scored as 4 out of 5 in the DJTI assessment. Here
                are YOUR Supporting values based on your responses to the assessment.
            </p>

            <RenderValues valueType="support" valueName="Supporting" valueList={supportValues} headBg="#6c4e90" rowBg1="#cfc8d9" rowBg2="#e8e4ec" />

            <p>
                Because your Supporting values are Truths for you at least 80% of the time, they are
                still an undeniable part of your identity, which is why we can also use them as predictors
                of the job passion workflow process that is your DreamJob Type.
            </p>
            <p>
                If you do not have any Core values at this time in your life, your Supporting values, by
                default, become your Core values for the purposes of discovering your DreamJob.
            </p>

            <h2>Your Sanctioning Values</h2>
            <p>
                Sanctioning values out-rank all others because they act as a filter to either affirm or
                discourage decisions you want to make based on your Core values or Supporting values.
                Here are YOUR Sanctioning values based on your responses to the assessment.
            </p>

            <RenderValues valueType="driver" valueName="Sanctioning" valueList={drivers} headBg="#8bb148" rowBg1="#d6e3c7" rowBg2="#ebf1e4" />
            
            <p>
                Unlike Core and Supporting values that serve a role in defining your DreamJob Type, your
                Sanctioning values are used as a filter to screen out specific job opportunities for your
                DreamJob Type that ultimately won't be a good fit for you.
            </p>
            <p>
                For example, if one of your Sanctioning values is FAMILY, a job opportunity that requires
                you to travel away from home three or four nights a week would conflict with that value
                and ultimately cause you grief. So, to avoid that conflict, you should search for a similar
                job opportunity where travel is not required.
            </p>

            <h2>Your Character Values</h2>
            <p>
                Character values tend to indicate how you will show up at any given job. So, you can also
                think of them as describing the kind of person you are in terms of your character. Here are
                YOUR Character values based on your responses to the assessment.
            </p>

            <RenderValues valueType="qualifier" valueName="Character" valueList={characterValues} headBg="#fdb50a" rowBg1="#ffefc1" rowBg2="#fee287" />

            <p>
                Similar to the role of Sanctioning values, your Character values are used to filter out job
                opportunities that ultimately wouldn't be a good fit for you to pursue.
            </p>
            <p>
                If, for example, PUNCTUALITY is not one of your Character values, you would want to avoid
                any job opportunities that require you to show up and complete tasks according to a strict schedule.
            </p>

            <h2>What To Do Next</h2>

            <div className="alert-warning p-4 my-3">
                <Row className="align-items-center">
                    <Col xs="12" sm="4" md="2">
                        <ReactGA.OutboundLink
                            eventLabel="download_workbook_image"
                            to="https://www.amazon.com/Dream-Job-Discovery-Passion-Inspires-ebook/dp/B09FB1HT6X/ref=sr_1_1?dchild=1&amp;keywords=Dream+Job+Discovery&amp;qid=1631055764&amp;s=books&amp;sr=1-1"
                            target="_blank"
                        ><img className="d-block mw-100" src={wbt} alt={`Dream Job Discovery`} /></ReactGA.OutboundLink>
                    </Col>
                    <Col>
                        <p className="mt-4">
                            Be sure to read Chapter 4 – What Are Your Truths? – in Dream Job Discovery for a detailed explanation
                            of what your Core, Supporting, Sanctioning, and Character values mean and how to use them to pinpoint
                            your dream job.
                        </p>
                    </Col>
                </Row>
            </div>
            <p>
                Now it's time to reveal your top-three DreamJob Types based on all of your Core and Supporting values. Click the
                button below to continue.
            </p>
        </Container>
    )
}