import React, { useEffect } from 'react'

import { Container, Row, Col } from 'reactstrap'

import ReactGA from 'react-ga'

import { getBrandName } from '../Branding'

const brandName = getBrandName()

export const ResultNextSteps = ({showFeedback=true}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <h1>Putting It All Together.</h1>


            <p>
                Congratulations! You've successfully reached a life-changing milestone in your career
                planning. Let's recap your progress.
            </p>
            <p>
                First, you pinpointed the personal values that are most important in guiding your thinking,
                decision making, and actions at this time in your life. And based on those values, our
                assessment has narrowed down the hundreds of possible categories of careers that you could
                consider into just three {brandName} Types.
            </p>
            <p>
                Then you were able to zero in on the one {brandName} Type that resonates best with you
                based on how closely it aligned with the shortlist of Core Values and Supporting Values
                that matter most to you right now.
            </p>
            <p>
                And finally, you've been able to narrow down the comprehensive list of occupation title
                suggestions for that {brandName} Type to a target list of just three to five {brandName} Title
                possibilities that warrant serious consideration.
            </p>
            <p>
                Here's what to do next:
            </p>
            <p className="text-primary">
                Conduct a focused online search for each of the {brandName} Titles on that target list to
                learn more about the job descriptions and qualifications they require.
            </p>
            <p>
                If you search for that job title on Google or any other online search engine, you'll be
                able to find detailed job descriptions, including the skills and education required to do
                those jobs well. You should end up with a target list of job opportunities you'd be
                interested in pursuing based on your personality, current strengths, and skillsets.
            </p>
            <p>
                Make sure that the skills and strengths you want to leverage in pursuing these job titles
                are ones that you enjoy doing. Over the years, your employer may have encouraged or
                required you to develop strengths and skills that are not in alignment with your values.
                These are the ones that you won't currently enjoy doing. You should avoid leveraging
                these as you move forward towards your {brandName}, as they will continue to be sources
                of frustration and unfulfillment.
            </p>

            <p className="text-primary">
                Now that you've fine-tuned your {brandName} Title target list, you have three choices for
                how to proceed:
            </p>
            <ol>
                <li>
                    DO IT YOURSELF &ndash; You can search job listings on your own to identify
                    opportunities that fit your {brandName} Title target list. Then you can research the
                    websites of those companies to identify only those whose values align most closely
                    with your Core and Supporting Values.
                </li>
                <li>
                    DO IT WITH HELP &ndash; You can participate in our next DREAMJOB DISCOVERY
                    WORKSHOP&trade;. In this online workshop, you'll discover the exact steps to convert
                    your list of values and {brandName} Titles into an action plan for finding companies
                    and job listings that are ideal for you.
                    {' '}
                    <ReactGA.OutboundLink
                        eventLabel="workshop"
                        to="https://go.bucketforms.com/sf/77874652"
                        target="_blank"
                    >CLICK TO LEARN MORE</ReactGA.OutboundLink>
                </li>
                <li>
                    DO IT TOGETHER &ndash; You can schedule a complimentary {brandName} Discovery call
                    with us. On that call we can give you more insights about your results and
                    discuss options for private coaching that might make more sense for you in your
                    current situation.
                    {' '}<br />
                    <ReactGA.OutboundLink
                        eventLabel="schedule_call"
                        to="https://services.dreamjobdiscovery.com/schedule"
                        target="_blank"
                    >CLICK TO SCHEDULE A CALL</ReactGA.OutboundLink>
                </li>
            </ol>
            {
                showFeedback &&
                <Container>
                    <Row>
                        <Col>
                            <Container>
                                <div style={{marginTop: "50px", marginBottom: "50px"}} className="text-center">
                                <ReactGA.OutboundLink
                                    eventLabel="survey"
                                    to="https://go.bucketsurveys.com/sf/4f306f28"
                                    target="_blank"
                                >
                                    <button className="btn" style={
                                        {
                                            paddingTop: "10px", paddingBottom: "10px", paddingLeft: "15px", paddingRight: "15px",
                                            fontWeight: "bold", color: "#2c3b59", backgroundColor: "#62CE62"}
                                        }>PLEASE HELP US WITH YOUR FEEDBACK</button>
                                </ReactGA.OutboundLink>
                                </div>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}