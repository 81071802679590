import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const AuthRoute = ({ component: Component, ...rest }) => {

    const redirectUrl = rest.useRedirect || '/';
    const isAuth = localStorage.getItem('session') || false;

    console.log(rest);
    return (<Route {...rest} render={props => (
        isAuth
            ? <Redirect to={{ pathname: redirectUrl, state: { from: props.location } }} />
            : <Component {...props} />
    )} />);
}