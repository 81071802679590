import Logo from './logo.svg'
import PrintLogo from './print-logo.svg'
import CoverImage from './report_cover.jpg'
import { HomePage } from './HomePage'
import { PrivacyPage } from './PrivacyPage'
import { TermsPage } from './TermsPage'
import { ValuesPage } from './ValuesPage'
import { ThemesPage } from './ThemesPage'
import { TitlesPage } from './TitlesPage'
import Favicon from './favicon.ico'

export default {
    favicon: Favicon,
    brandName: 'Job Passion',
    brandNameFull: 'Job Passion Assessment',
    displayDemographics: false,
    Logo: Logo,
    PrintLogo,
    CoverImage,
    HomePage,
    SignupPage: null,
    PrivacyPage,
    TermsPage,
    ValuesPage,
    ThemesPage,
    TitlesPage
}