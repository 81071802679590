import React from "react"
import { Container } from 'reactstrap'

export const ValuesPage = ({RenderValues, coreValues, supportValues, drivers, characterValues}) => {
    return (
        <Container>
            <h1>Your Authentic-Self Values&trade;</h1>
            <p>Congratulations!</p>
            <p>
                From a list of 118 value statements, you've successfully chosen the ones that best describe
                who you are.
            </p>
            <p>
                These values are like DNA because they define what's truly important to you. You could think
                of them as being a personal compass. They're the guiding principles you subconsciously use to
                evaluate the world around you and they can be used to help you find a job you'll enjoy.
            </p>
            <p>
                That's because, if you can find work that fits with your values, you're more likely to feel
                joy, meaning, and fulfillment. When your values and your job don't align, you're more likely
                to feel stressed, anxious, or unhappy. 
            </p>
            <p>
                We've divided the values that matter most to you into four categories: Core, Supporting,
                Sanctioning, and Character. 
            </p>
            <p>Core and Sanctioning values are used to define your Job Passion Types. </p>
            <p>
                Sanctioning and Character values serve as filters to help you make the best choice between
                different job opportunities that align with your Job Passion Types. 
            </p>
            <p>You'll discover more about how to use your values in the Appendix of this report. </p>

            <h2>Your Core Values</h2>
            <p>
                Core values are the ones that are most important in guiding your decisions and actions
                every day because they are almost always important to you. You will follow these values
                almost without exception, regardless of the situation. So, think of these as being your
                "pretty much all the time" values.
            </p>
            <p>
                For example, if "honesty" was a Core value for someone they would "pretty much all the time"
                give an honest opinion even if they knew it might upset another person. 
            </p>
            <p>Here is your unique set of Core values based on your responses to the assessment.</p>

            <RenderValues valueType="core" valueName="Core" valueList={coreValues} headBg="#3f6caf" rowBg1="#c5cee2" rowBg2="#e3e8f2"
                emptyDesc="Your responses did not reveal any core values for you. This result is no cause for concern. It merely indicates there are no values that you genuinely feel are important enough at this time in your life to be a priority in guiding the way you think, make decisions or act 100% of the time. Instead, you have supporting values that very often, but not always, help guide your life. So, you should treat your Supporting Values as Core Values for the purposes of finding work you love to do."
            />

            <h2>Your Supporting Values</h2>
            <p>
                As the name suggests, Supporting values play a key "supporting role" in defining who you truly
                are. These are the values that your subconscious guides you to follow at least 80% of the time
                depending on the situation. So, think of these as being your "it depends" values. 
            </p>
            <p>
                For example, if "honesty" was a Supporting value for someone they would give an honest opinion
                at least 80% of the time but might choose to tell a little fib to avoid hurting someone’s feelings. 
            </p>
            <p>Based on your responses to the assessment, here are your Supporting values.</p>

            <RenderValues valueType="support" valueName="Supporting" valueList={supportValues} headBg="#6c4e90" rowBg1="#cfc8d9" rowBg2="#e8e4ec" />

            <p>
                If you do not have any Core values at this time in your life, your Supporting values, by default,
                become your Core values for the purposes of discovering your Job Passion Types.
            </p>

            <h2>Your Sanctioning Values</h2>
            <p>
                Your Sanctioning values are used as a filter to screen out specific job opportunities that
                ultimately won't be a good fit for you, even though they align nicely with your Job Passion
                Types. So, think of them as your "go" versus "no go" values.
            </p>
            <p>
                Let's say, for example, someone has two job opportunities that they know will be a good fit
                with their Job Passion Types. One of them requires then to be away from home three or four
                nights a week, while the other allows them to be home for dinner each night with their family.
                If that person has a Sanctioning value of "family", missing those family dinners each night
                would likely make them feel guilty and cause them some stress. So, the "go" decision would
                be to take the job that doesn't require travel. 
            </p>
            <p>
                You'll find an explanation of all the Sanctioning values and how to use them to help you make
                "go" versus "no go" decisions in the Appendix.
            </p>
            <p>Here are YOUR Sanctioning values based on your responses to the assessment.</p>

            <RenderValues valueType="driver" valueName="Sanctioning" valueList={drivers} headBg="#8bb148" rowBg1="#d6e3c7" rowBg2="#ebf1e4" />

            <h2>Your Character Values</h2>
            <p>
                Your Character values describe the kind of person you tend to be in terms of your character.
                They are clues to how you might prefer to do things while at work, so you can think of them
                as your "work style" values.
            </p>
            <p>
                Similar to the role of Sanctioning values, your Character values are used to filter out job
                opportunities that ultimately wouldn't be a good fit.
            </p>
            <p>
                If, for example, someone has a Character value of "punctuality", a job where strict adherence
                to schedules and deadlines would likely be a good fit for them. On the other hand, if someone
                does not have the Character value of "punctuality" they would likely feel more at home in a
                job where they can come and go as they please and work completion has no hard deadlines.
            </p>
            <p>
                The Appendix includes a complete list of all the Character values and an explanation of how to
                use them as a filter to achieve the best job fit.
            </p>
            <p>Based on your responses to the assessment, here are your Character values.</p>

            <RenderValues valueType="qualifier" valueName="Character" valueList={characterValues} headBg="#fdb50a" rowBg1="#ffefc1" rowBg2="#fee287" />

            <h2>Summary</h2>
            <p>
                Your responses to the assessment have identified the Core, Supporting, Sanctioning, and Character
                values that are most important in shaping the purpose and direction of your life currently. 
            </p>
            <p>
                When you are doing work that directly aligns with your values you will feel right at home and
                truly connected to what you're doing and the bigger picture at the company that employs you.
                People who experience this kind of match often enjoy their jobs more, do better work, and find
                real meaning in what they do.
            </p>
            <p>
                On the flip side, if your values clash with the company's, you might feel let down, stressed out,
                or even at odds with what's going on around you. When there's a big gap between what's important
                to you and what your company values, it can be tough to give your best at work, which might lead
                to not feeling great about your job or how you're doing.
            </p>

            <h2>What's Next</h2>
            <p>
                Click the button below to reveal your Job Passion Types based on your Core and Supporting values.
            </p>
            
            <div>&nbsp;</div>
        </Container>
    )
}