import { demographicConstants as constants } from '../constants';
import { demographicServices as services } from '../services';

export const demographicActions = {
    get,
    save
};

function get() {
    return dispatch => {
        dispatch(request());

        services.get()
            .then(
                (data) => {                    
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: constants.REQUEST } }
    function success(data) { return { type: constants.SUCCESS, data } }
    function failure(error) { return { type: constants.FAILURE, error } }
}

function save(demoData) {
    return dispatch => {
        dispatch(request());

        services.save(demoData)
            .then(
                (data) => {                    
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: constants.REQUEST } }
    function success(data) { return { type: constants.SUCCESS, data } }
    function failure(error) { return { type: constants.FAILURE, error } }
}